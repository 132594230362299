import { isApiFilter } from '../../modules/Sharing/Components/SharesTable/SharesTableComponent.constants';
import { DeviceContext, OrderContext } from '../../types';

export const TableName = {
  Fleet: 'fleet',
  Vehicles: 'vehicles',
  VehicleDetails: 'vehicle-details',
  Sharings: 'sharings',
  OrdersReceived: 'orders-received',
  OrdersSent: 'orders-sent',
  OrderDetails: 'order-details',
  SelectedCompany: 'selected-company',
  RequestsOutgoing: 'request-outgoing',
  RequestIncoming: 'request-incoming',
  DevicesNew: 'devices-new',
  DevicesConnected: 'devices-connected',
  DevicesDeactivated: 'devices-deactivated',
};

export const tableNames = {
  [DeviceContext.NEW]: TableName.DevicesNew,
  [DeviceContext.CONNECTED]: TableName.DevicesConnected,
  [DeviceContext.DEACTIVATED]: TableName.DevicesDeactivated,
};

// interface
export const isCurrentInterfaceConfigurationLoading = (state) => state.personalization.interfaceConfiguration.loading;
export const getCurrentInterfaceConfigurationsRoot = (
  state,
) => state.personalization.interfaceConfiguration.configurations;
export const getCurrentInterfaceConfiguration = (
  tableName,
) => (state) => state.personalization.interfaceConfiguration.configurations[tableName];
export const getVehiclesFilters = (state) => getCurrentInterfaceConfiguration(TableName.Vehicles)(state).filters;
export const getVehiclesColumns = (state) => getCurrentInterfaceConfiguration(TableName.Vehicles)(state).columns;
export const getSharingFilters = (state) => getCurrentInterfaceConfiguration(TableName.Sharings)(state).filters;
export const getRequestsOutgoingFilters = (
  state,
) => getCurrentInterfaceConfiguration(TableName.RequestsOutgoing)(state).filters;
export const getRequestsIncomingFilters = (
  state,
) => getCurrentInterfaceConfiguration(TableName.RequestIncoming)(state).filters;
export const getOrdersFilters = (orderContext) => (
  state,
) => getCurrentInterfaceConfiguration(
  orderContext === OrderContext.IN ? TableName.OrdersReceived : TableName.OrdersSent,
)(state).filters;
export const getBothOrdersFilters = (state) => ({
  [OrderContext.IN]: getOrdersFilters(OrderContext.IN)(state),
  [OrderContext.OUT]: getOrdersFilters(OrderContext.OUT)(state),
});
export const getSharingApiFilters = (state) => {
  const { filters } = getCurrentInterfaceConfiguration(TableName.Sharings)(state);
  const apiFilters = {};
  Object.entries(filters).filter(([key]) => isApiFilter(key)).forEach(([key, val]) => { apiFilters[key] = val; });
  return apiFilters;
};
export const getDevicesFilters = (tableName) => (
  state,
) => getCurrentInterfaceConfiguration(
  tableName,
)(state).filters;

export const getVehicleDetailsRefreshInterval = (
  state,
) => getCurrentInterfaceConfiguration(TableName.VehicleDetails)(state).refreshIntervalSeconds;
export const getRefreshIntervalSeconds = (tableName) => (state) => {
  const interfaceConfiguration = getCurrentInterfaceConfiguration(tableName)(state);
  return interfaceConfiguration ? interfaceConfiguration.refreshIntervalSeconds : null;
};
export const getSharingRefreshInterval = (state) => getRefreshIntervalSeconds(TableName.Sharings)(state);

// table
const getTableConfigurationsRoot = (state) => state.personalization.tableConfigurations;
export const isTableConfigurationsLoading = (state) => getTableConfigurationsRoot(state).loading;
export const getTableConfigurations = (context) => (
  state,
) => getTableConfigurationsRoot(state).configurations[context];
export const getCurrentTableConfigurationId = (context) => (
  state,
) => getTableConfigurationsRoot(state).currentConfigurationId[context];
export const getCurrentTableConfiguration = (context) => (state) => {
  const id = getCurrentTableConfigurationId(context)(state);
  return id ? getTableConfigurations(context)(state)[id] : { filters: { } };
};
export const getVehicleFilterModalData = (state) => getTableConfigurationsRoot(state).vehicleFilterModal;
export const isLastUsedTableConfigurationCached = (context) => (
  state,
) => getTableConfigurationsRoot(state).currentConfigurationCached[context];
export const isTableConfigurationsCached = (context) => (
  state,
) => getTableConfigurationsRoot(state).configurationsCached[context];
