import { DeviceContext } from '../../../../types';

const SET_NEW_DEVICES_PAGINATION = 'SET_NEW_DEVICES_PAGINATION';
const SET_CONNECTED_DEVICES_PAGINATION = 'SET_CONNECTED_DEVICES_PAGINATION';
const SET_DEACTIVATED_DEVICES_PAGINATION = 'SET_DEACTIVATED_DEVICES_PAGINATION';

export const SET_DEVICES_PAGINATION = {
  [DeviceContext.NEW]: SET_NEW_DEVICES_PAGINATION,
  [DeviceContext.CONNECTED]: SET_CONNECTED_DEVICES_PAGINATION,
  [DeviceContext.DEACTIVATED]: SET_DEACTIVATED_DEVICES_PAGINATION,
};

export const setDevicesPagination = ({ page, size }, deviceContext = DeviceContext.CONNECTED) => ({
  type: SET_DEVICES_PAGINATION[deviceContext],
  payload: {
    page,
    size,
  },
});

export const resetDevicesPagination = (deviceContext = DeviceContext.CONNECTED) => ({
  type: SET_DEVICES_PAGINATION[deviceContext],
  payload: {
    page: 1,
  },
});
