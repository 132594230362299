import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Modal } from 'antd';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useHistory } from 'react-router-dom';
import RoutePath from '../../../RoutePath';
import { LanguageService as i18n } from '../../../services';
import {
  createTelematicAccount,
  submitUnknownTelematicProvider,
} from '../../../store/actions';
import { getSelectedCompany, getTelematicAccountFormConfig } from '../../../store/selectors';
import { ERROR_CODE } from '../../../utils/apiError';
import {
  isError, isSuccess, getErrorCode, getPayloadData,
} from '../../../utils/responseUtils';
import { uuidv4 } from '../../../utils/uuid';
import TelematicAccountForm from '../Components/TelematicAccountForm';
import { TelematicAccountOperationType } from '../../../types/enums/telematicAccountFormViewType.enum';

export const submittedVinsSuccessModal = ({
  payloadData, telematicAccountFormConfig, title, content, goToDetailsPage, isAddVehiclesOperation,
}) => {
  if (payloadData?.providerAccount?.credentials?.vins?.details?.lastSubmitted) {
    const {
      providerAccount: { providerId: responseProviderId, credentials: { vins: { details: { lastSubmitted } } } },
    } = payloadData;
    const providerName = telematicAccountFormConfig
      .find(({ providerId }) => providerId === responseProviderId).displayName;
    if (lastSubmitted?.length > 0) {
      return {
        title: title.concat(isAddVehiclesOperation ? '.CREDENTIALS_SUBMITTED' : '.DEFAULT'),
        content: i18n.t(content.concat('.VEHICLES_SUBMITTED'), { vehiclesCount: lastSubmitted.length, providerName }),
        constructor: Modal.success,
        onOk: goToDetailsPage,
        okText: 'TELEMATICS.ACCOUNT.BUTTON_GO_TO_DETAILS_PAGE',
      };
    }
  }
  return undefined;
};

const TelematicAccountCreationPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [accountCreationForm] = Form.useForm();

  const contextCompanyId = useSelector(getSelectedCompany);
  const { telematicAccountFormConfig } = useSelector(getTelematicAccountFormConfig);

  const [submitClicked, setSubmitClicked] = useState(false);
  const redirectToPathLocalStorageKey = (path) => `REDIRECT_TO_${path}`;

  const confirmationModalKeysAndConstructor = (
    response, isUnknownProvider, handleUserNeedsSupport,
  ) => {
    const forSuccess = isSuccess(response);
    const forFailure = isError(response);
    const statusSuffix = (forSuccess && 'SUCCESS') || (forFailure && 'ERROR') || 'STARTED';
    const typeInterfix = isUnknownProvider ? 'UNKNOWN_PROVIDER' : 'NEW_ACCOUNT';
    const title = `TELEMATICS.ACCOUNT.CREATE.CONFIRMATION.TITLE.${typeInterfix}.${statusSuffix}`;
    const content = `TELEMATICS.ACCOUNT.CREATE.CONFIRMATION.CONTENT.${typeInterfix}.${statusSuffix}`;

    if (forSuccess) {
      const payloadData = getPayloadData(response);
      const accountDetailsRoutePath = RoutePath.telematicAccountDetails(payloadData.providerAccount?.accountId);
      const goToDetailsPage = () => {
        localStorage.removeItem(redirectToPathLocalStorageKey(accountDetailsRoutePath));
        history.push(accountDetailsRoutePath);
      };
      const goBackToTelematicsMain = () => (history.push(RoutePath.telematicAccountsView));

      const successModal = submittedVinsSuccessModal({
        payloadData, telematicAccountFormConfig, title, content, goToDetailsPage,
      });
      if (successModal) {
        return { ...successModal, onOkRoutePath: accountDetailsRoutePath };
      }
      return {
        title: title.concat('.DEFAULT'),
        content: (
          <>
            {i18n.t(content.concat('.DEFAULT'))}
          </>
        ),
        constructor: isUnknownProvider ? Modal.warning : Modal.success,
        onOk: isUnknownProvider ? goBackToTelematicsMain : goToDetailsPage,
        okText: isUnknownProvider ? 'COMMON.OK' : 'TELEMATICS.ACCOUNT.BUTTON_GO_TO_DETAILS_PAGE',
        onOkRoutePath: accountDetailsRoutePath,
      };
    }

    const errorCode = getErrorCode(response);
    switch (errorCode) {
      case ERROR_CODE.SCANIA_VAT_NUMBER_NOT_FOUND:
      case ERROR_CODE.CONNECTIVITY_AGREEMENT_NOT_SIGNED:
        return {
          title: title.concat(`.${errorCode}`),
          content: i18n.t(content.concat(`.${errorCode}`)),
          constructor: Modal.error,
          width: 463,
          onOk: handleUserNeedsSupport,
          okText: 'TELEMATICS.ACCOUNT.BUTTON_NEED_SUPPORT',
        };
      case ERROR_CODE.TELEMATIC_PROVIDER_ACCOUNT_EXISTS:
        return {
          title: title.concat('.DEFAULT'),
          content: i18n.t(content.concat(`.${errorCode}`)),
          constructor: Modal.error,
          width: 463,
        };
      default:
        return {
          title: title.concat('.DEFAULT'),
          content: i18n.t(content.concat('.DEFAULT')),
          constructor: forFailure ? Modal.error : Modal.info,
        };
    }
  };

  const dispatchTelematicAccountOrUnknownProvider = ({ payload, isUnknownProvider, handleUserNeedsSupport }) => {
    if (submitClicked) {
      return;
    }
    setSubmitClicked(true);
    const action = isUnknownProvider
      ? submitUnknownTelematicProvider
      : createTelematicAccount;
    dispatch(action(payload, contextCompanyId))
      .then((response) => {
        const modalInfo = confirmationModalKeysAndConstructor(
          response,
          isUnknownProvider,
          handleUserNeedsSupport,
        );
        setSubmitClicked(false);
        const modalType = modalInfo.constructor;
        const isSuccessModal = modalType === Modal.success;
        const modal = modalType({
          key: uuidv4(),
          title: i18n.t(modalInfo.title),
          content: modalInfo.content,
          onOk: modalInfo.onOk,
          okText: i18n.t(modalInfo.okText),
          centered: true,
          width: modalInfo.width,
          ...(isSuccessModal ? { keyboard: false } : { closable: true, maskClosable: true }),
        });
        if (isSuccessModal) {
          localStorage.setItem(redirectToPathLocalStorageKey(modalInfo.onOkRoutePath), true);
          setTimeout(() => {
            if (localStorage.getItem(redirectToPathLocalStorageKey(modalInfo.onOkRoutePath))) {
              modalInfo.onOk();
              modal.destroy();
            }
          }, 5000);
        }
      });
  };

  return (
    <>
      <BreadcrumbsItem to={RoutePath.telematicAccountsView}>{i18n.t('BREADCRUMB.TELEMATICS')}</BreadcrumbsItem>
      <BreadcrumbsItem to={RoutePath.telematicAccountsCreate}>
        {i18n.t('BREADCRUMB.TELEMATICS_NEW_ACCOUNT')}
      </BreadcrumbsItem>

      <TelematicAccountForm
        form={accountCreationForm}
        submitDispatchFunction={dispatchTelematicAccountOrUnknownProvider}
        creationView
        operationType={TelematicAccountOperationType.CREATE}
        submitClicked={submitClicked}
        setSubmitClicked={setSubmitClicked}
      />
    </>
  );
};

TelematicAccountCreationPage.propTypes = {};

TelematicAccountCreationPage.defaultProps = {};

export default TelematicAccountCreationPage;
