const SUBMIT_NEW_VEHICLES_FORM_ERROR = 'SUBMIT_NEW_VEHICLES_FORM_ERROR';
const SUBMIT_NEW_VEHICLES_FORM_SUCCESS = 'SUBMIT_NEW_VEHICLES_FORM_SUCCESS';
const SUBMIT_CONNECTED_VEHICLES_FORM_ERROR = 'SUBMIT_CONNECTED_VEHICLES_FORM_ERROR';
const SUBMIT_CONNECTED_VEHICLES_FORM_SUCCESS = 'SUBMIT_CONNECTED_VEHICLES_FORM_SUCCESS';
const TAB_CHANGE = 'TAB_CHANGE';
const REFRESH = 'REFRESH';

export const DEVICES_ACTION = {
  SUBMIT_NEW_VEHICLES_FORM_ERROR,
  SUBMIT_NEW_VEHICLES_FORM_SUCCESS,
  SUBMIT_CONNECTED_VEHICLES_FORM_ERROR,
  SUBMIT_CONNECTED_VEHICLES_FORM_SUCCESS,
  TAB_CHANGE,
  REFRESH,
  SUBMIT_NEW_VEHICLES_FORM: [SUBMIT_NEW_VEHICLES_FORM_ERROR, SUBMIT_NEW_VEHICLES_FORM_SUCCESS],
  SUBMIT_CONNECTED_VEHICLES_FORM: [SUBMIT_CONNECTED_VEHICLES_FORM_ERROR, SUBMIT_CONNECTED_VEHICLES_FORM_SUCCESS],
  SUBMIT_VEHICLES_FORM: [SUBMIT_NEW_VEHICLES_FORM_ERROR, SUBMIT_NEW_VEHICLES_FORM_SUCCESS,
    SUBMIT_CONNECTED_VEHICLES_FORM_ERROR, SUBMIT_CONNECTED_VEHICLES_FORM_SUCCESS],
};

export const ConnectionStatus = {
  PENDING: 'PENDING',
  NEEDS_VERIFICATION: 'NEEDS_VERIFICATION',
  CONNECTED: 'CONNECTED',
  CONNECTION_ISSUE: 'CONNECTION_ISSUE',
  DEACTIVATED: 'DEACTIVATED',
};
