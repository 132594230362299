import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Empty, Form, Input, Row, Select, Tag,
} from 'antd';
import {
  CheckCircleOutlined, PauseCircleOutlined, SearchOutlined, SyncOutlined, WarningOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { LanguageService as i18n } from '../../../../services';
import {
  DateTime, InputFilterDropdown, SelectFilterDropdown, StyledTable, TableLoadingComponent,
} from '../../../../components';
import { DeviceContext, VehicleType } from '../../../../types';
import {
  DevicesColumnKey as ColumnKey, DevicesColumnWidth as ColumnWidth, FILTERS,
  connectionStatusOptionsByContext,
} from './DevicesTable.constants';
import { DATE_WITH_DOTS } from '../../../../utils/constants/timeFormats';
import { devicesEditMode } from '../../../../store/selectors';
import { COUNTRIES } from '../../../../utils/constants/countries';
import {
  ERROR_CODE, VIN_ERROR_CODES, LICENSE_PLATE_ERROR_CODES, licensePlateCountryCodeField, licensePlateNumberField,
  vehicleTypeField, vinField,
  LICENSE_PLATE_COUNTRY_CODE_ERROR_CODES,
  VEHICLE_TYPE_ERROR_CODES,
} from './DevicesForm.constants';
import { addAllRowErrors, addRowError, removeRowErrors } from '../../../../store/actions';
import { ConnectionStatus } from './DevicesContent.constants';

const TableWithContent = styled(StyledTable)`
  .ant-table-column-sorters {
    padding: 0;
  }
`;

const NotEditableField = styled.span`
  opacity: 0.45;
`;

const countryCodeOptions = COUNTRIES.map(({ isoCode }) => ({
  value: isoCode,
  label: isoCode,
}));

const vehicleTypes = [VehicleType.TRAILER, VehicleType.TRUCK, VehicleType.VAN];

const POSITION_TIMESTAMP_DATE_TIME = 'YYYY-MM-DD HH:mm:ss';
const disabledSelectionOnTargetClasses = ['ant-input', 'ant-select', 'ant-btn'];

const DevicesTable = ({
  devices, onSetPagination, onSetSorting, filters, updateFilters, isTableView,
  viewGpsConnectionDisabled, deviceContext, form, rows,
}) => {
  const dispatch = useDispatch();
  const vehicleTypeOptions = vehicleTypes.map((type) => ({
    value: type,
    label: i18n.t(`DICTIONARY.VEHICLE_TYPE.${type}`),
  }));
  const { isEnabled: isEditMode, rowSelectionProps, id: editModeId } = useSelector(devicesEditMode(deviceContext));

  const selectedRowKeys = rows.filter((row) => row.isSelected).map((row) => row.deviceId);
  const selectedRowsWithoutErrors = rows.filter(({ isSelected, errors }) => isSelected && !errors.length)
    .map((row) => row.deviceId);

  const {
    data: {
      page: { totalElements }, loading,
    }, pagination, sorting,
  } = devices;

  const validateRow = (deviceId) => {
    form.validateFields([
      vinField(deviceId), licensePlateCountryCodeField(deviceId), licensePlateNumberField(deviceId),
      vehicleTypeField(deviceId),
    ]);
  };

  const validateAllRows = () => {
    form.validateFields();
  };

  useEffect(() => {
    if (!selectedRowsWithoutErrors.length) {
      return;
    }
    const fieldsToValidate = selectedRowsWithoutErrors.flatMap((deviceId) => [
      vinField(deviceId), licensePlateCountryCodeField(deviceId), licensePlateNumberField(deviceId),
      vehicleTypeField(deviceId),
    ]);
    form.validateFields(fieldsToValidate)
      .catch((error) => {
        const errorRows = Object.entries(Object.groupBy(error.errorFields, ({ name }) => name[1]))
          .map((row) => ({ deviceId: row[0], errors: row[1].flatMap((e) => e.errors) }));
        dispatch(addAllRowErrors(deviceContext, errorRows));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editModeId, form, deviceContext, dispatch]);

  const titleWithOptional = (titleName) => (
    <>
      {i18n.t(titleName)}
      {!viewGpsConnectionDisabled ? (
        <span style={{ opacity: 0.45 }}>
          {i18n.t('TELEMATICS.ACCOUNT.DETAILS.DEVICES.COLUMNS.POSTFIX_OPTIONAL')}
        </span>
      ) : <></>}
    </>
  );

  const getSortOrder = (sortBy) => (sorting && sorting.field === sortBy ? sorting.order : false);

  const renderNotEditableField = (value) => (isEditMode ? (<NotEditableField>{value}</NotEditableField>) : value);

  const renderDeviceId = (_, { deviceId }) => (isEditMode ? (
    <Form.Item
      name={[deviceId, ColumnKey.deviceId]}
      initialValue={deviceId}
      hidden
    />
  ) : <></>);

  const renderVehicleId = (_, { deviceId, vehicleId }) => (isEditMode ? (
    <Form.Item
      name={[deviceId, ColumnKey.vehicleId]}
      initialValue={vehicleId}
      hidden
    />
  ) : <></>);

  const vinValidator = (row) => () => {
    if (!row.isSelected) {
      return Promise.resolve();
    }
    const vinErrors = row.errors.filter((error) => VIN_ERROR_CODES.includes(error));
    if (vinErrors.length) {
      dispatch(removeRowErrors(deviceContext, row, vinErrors));
    }
    return Promise.resolve();
  };

  const renderVin = (_, row) => (!viewGpsConnectionDisabled && isEditMode ? (
    <Form.Item
      name={[row.deviceId, ColumnKey.vin]}
      initialValue={row.vin}
      rules={[
        { validator: vinValidator(row) },
      ]}
    >
      <Input
        size="small"
        style={{
          width: 145,
        }}
        defaultValue={row.vin}
        placeholder="input"
      />
    </Form.Item>
  ) : renderNotEditableField(row.vin));

  const licensePlateCountryCodeValidator = (row) => (_, value) => {
    if (!row.isSelected) {
      return Promise.resolve();
    }
    if (!value?.trim()) {
      return Promise.reject();
    }
    const selectValueErrors = row.errors.filter((error) => LICENSE_PLATE_COUNTRY_CODE_ERROR_CODES.includes(error));
    if (selectValueErrors.length) {
      dispatch(removeRowErrors(deviceContext, row, selectValueErrors));
    }
    return Promise.resolve();
  };

  const renderLicensePlateCountryCode = (_, row) => (isEditMode ? (
    <Form.Item
      name={[row.deviceId, ColumnKey.licensePlateCountryCode]}
      initialValue={row.licensePlateCountryCode}
      rules={[
        { validator: licensePlateCountryCodeValidator(row), message: ERROR_CODE.MISSING_LICENSE_PLATE_COUNTRY_CODE },
      ]}
    >
      <Select
        size="small"
        style={{
          width: 57,
        }}
        defaultValue={row.licensePlateCountryCode}
        options={countryCodeOptions}
        showSearch
      />
    </Form.Item>
  ) : row.licensePlateCountryCode);

  const licensePlateValidator = (row) => (_, value) => {
    if (!row.isSelected) {
      return Promise.resolve();
    }
    if (!value?.trim()) {
      if (form.isFieldTouched(licensePlateNumberField(row.deviceId))) {
        dispatch(addRowError(deviceContext, row, ERROR_CODE.MISSING_LICENSE_PLATES));
      }
      return Promise.reject();
    }
    const lpErrors = row.errors.filter((error) => LICENSE_PLATE_ERROR_CODES.includes(error));
    if (lpErrors.length) {
      dispatch(removeRowErrors(deviceContext, row, lpErrors));
    }
    return Promise.resolve();
  };

  const renderLicensePlateNumber = (_, row) => (isEditMode ? (
    <Form.Item
      name={[row.deviceId, ColumnKey.licensePlateNumber]}
      initialValue={row.licensePlateNumber}
      rules={[
        {
          validator: licensePlateValidator(row), message: ERROR_CODE.MISSING_LICENSE_PLATES,
        },
      ]}
    >
      <Input
        size="small"
        style={{
          width: 91.5,
        }}
        defaultValue={row.licensePlateNumber}
        placeholder="Enter"
      />
    </Form.Item>
  ) : row.licensePlateNumber);

  const mapVehicleType = (vehicleType) => (vehicleTypes.includes(vehicleType) ? vehicleType : null);

  const vehicleTypeValidator = (row) => (_, value) => {
    if (!row.isSelected) {
      return Promise.resolve();
    }
    if (!value?.trim()) {
      return Promise.reject();
    }
    const selectValueErrors = row.errors.filter((error) => VEHICLE_TYPE_ERROR_CODES.includes(error));
    if (selectValueErrors.length) {
      dispatch(removeRowErrors(deviceContext, row, selectValueErrors));
    }
    return Promise.resolve();
  };

  const renderVehicleType = (_, row) => (!viewGpsConnectionDisabled && isEditMode ? (
    <Form.Item
      name={[row.deviceId, ColumnKey.vehicleType]}
      initialValue={mapVehicleType(row.vehicleType)}
      rules={[
        { validator: vehicleTypeValidator(row), message: ERROR_CODE.MISSING_VEHICLE_TYPE },
      ]}
    >
      <Select
        size="small"
        style={{
          width: 84,
        }}
        defaultValue={mapVehicleType(row.vehicleType)}
        placeholder="Select"
        options={vehicleTypeOptions}
        showSearch
      />
    </Form.Item>
  ) : renderNotEditableField(mapVehicleType(row.vehicleType)));

  const getConnectionStatusLabel = (status) => i18n.t(`TELEMATICS.ACCOUNT.DETAILS.DEVICES.STATUSES.${status}.LABEL`);
  const getConnectionStatusProps = (status) => {
    switch (status) {
      case ConnectionStatus.PENDING:
        return {
          color: 'blue',
          icon: <SyncOutlined />,
        };
      case ConnectionStatus.NEEDS_VERIFICATION:
        return {
          color: 'orange',
          icon: <WarningOutlined />,
        };
      case ConnectionStatus.CONNECTED:
        return {
          color: 'green',
          icon: <CheckCircleOutlined />,
        };
      case ConnectionStatus.CONNECTION_ISSUE:
        return {
          color: 'orange',
          icon: <WarningOutlined />,
        };
      case ConnectionStatus.DEACTIVATED:
        return {
          icon: <PauseCircleOutlined />,
        };
      default:
        return {};
    }
  };

  const renderConnectionStatus = (_, { connectionStatus }) => (
    <Row justify="center">
      <Tag
        key={connectionStatus}
        style={{ margin: '5px 8px 5px 0px' }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getConnectionStatusProps(connectionStatus)}
      >
        {getConnectionStatusLabel(connectionStatus)}
      </Tag>
    </Row>
  );

  const renderLastPing = (_, { lastTraceTimestamp }) => renderNotEditableField(
    <DateTime value={lastTraceTimestamp} dateTimeFormat={POSITION_TIMESTAMP_DATE_TIME} />,
  );

  const renderProviderDeviceId = (_, { providerDeviceId }) => renderNotEditableField(providerDeviceId);

  const renderConnectedDate = (_, { creationDate }) => renderNotEditableField(
    <DateTime value={creationDate} dateTimeFormat={DATE_WITH_DOTS} />,
  );

  const deviceIdColumn = {
    dataIndex: ColumnKey.deviceId,
    key: ColumnKey.deviceId,
    render: renderDeviceId,
    width: 0,
  };

  const vehicleIdColumn = {
    dataIndex: ColumnKey.vehicleId,
    key: ColumnKey.vehicleId,
    render: renderVehicleId,
    width: 0,
  };

  const vinColumn = {
    title: titleWithOptional('TELEMATICS.ACCOUNT.DETAILS.DEVICES.COLUMNS.VIN'),
    dataIndex: ColumnKey.vin,
    key: ColumnKey.vin,
    sorter: true,
    sortOrder: getSortOrder(ColumnKey.vin),
    filteredValue: filters.VIN,
    filterDropdown: InputFilterDropdown(FILTERS.VIN, updateFilters, i18n.t),
    filterIcon: <SearchOutlined />,
    editable: true,
    render: renderVin,
    width: ColumnWidth.vin,
  };

  const licensePlateCountryCodeColumn = {
    title: i18n.t('TELEMATICS.ACCOUNT.DETAILS.DEVICES.COLUMNS.COUNTRY'),
    dataIndex: ColumnKey.licensePlateCountryCode,
    key: ColumnKey.licensePlateCountryCode,
    sorter: true,
    sortOrder: getSortOrder(ColumnKey.licensePlateCountryCode),
    editable: true,
    render: renderLicensePlateCountryCode,
    width: ColumnWidth.licensePlateCountryCode,
  };

  const licensePlateNumberColumn = {
    title: i18n.t('TELEMATICS.ACCOUNT.DETAILS.DEVICES.COLUMNS.LICENSE_PLATE'),
    dataIndex: ColumnKey.licensePlateNumber,
    key: ColumnKey.licensePlateNumber,
    sorter: true,
    sortOrder: getSortOrder(ColumnKey.licensePlateNumber),
    filteredValue: filters.LICENSE_PLATE_NUMBER,
    filterDropdown: InputFilterDropdown(FILTERS.LICENSE_PLATE_NUMBER, updateFilters, i18n.t),
    filterIcon: <SearchOutlined />,
    editable: true,
    render: renderLicensePlateNumber,
    width: ColumnWidth.licensePlateNumber,
  };

  const vehicleTypeColumn = {
    title: i18n.t('TELEMATICS.ACCOUNT.DETAILS.DEVICES.COLUMNS.VEHICLE_TYPE'),
    dataIndex: ColumnKey.vehicleType,
    key: ColumnKey.vehicleType,
    sorter: true,
    sortOrder: getSortOrder(ColumnKey.vehicleType),
    editable: true,
    render: renderVehicleType,
    width: ColumnWidth.vehicleType,
  };

  const connectionStatusColumn = {
    title: i18n.t('TELEMATICS.ACCOUNT.DETAILS.DEVICES.COLUMNS.CONNECTION_STATUS'),
    dataIndex: ColumnKey.connectionStatus,
    key: ColumnKey.connectionStatus,
    filteredValue: filters.CONNECTION_STATUS,
    filterDropdown: SelectFilterDropdown(
      FILTERS.CONNECTION_STATUS.name, connectionStatusOptionsByContext(deviceContext), updateFilters, i18n.t,
    ),
    filterIcon: <SearchOutlined />,
    render: renderConnectionStatus,
    width: ColumnWidth.connectionStatus,
  };

  const providerDeviceIdColumn = {
    title: i18n.t('TELEMATICS.ACCOUNT.DETAILS.DEVICES.COLUMNS.DEVICE_ID'),
    dataIndex: ColumnKey.providerDeviceId,
    key: ColumnKey.providerDeviceId,
    render: renderProviderDeviceId,
    sorter: true,
    sortOrder: getSortOrder(ColumnKey.providerDeviceId),
    filteredValue: filters.PROVIDER_DEVICE_ID,
    filterDropdown: InputFilterDropdown(FILTERS.PROVIDER_DEVICE_ID, updateFilters, i18n.t),
    filterIcon: <SearchOutlined />,
    width: ColumnWidth.providerDeviceId,
  };

  const lastTraceTimestampColumn = {
    title: i18n.t('TELEMATICS.ACCOUNT.DETAILS.DEVICES.COLUMNS.LAST_PING'),
    dataIndex: ColumnKey.lastTraceTimestamp,
    key: ColumnKey.lastTraceTimestamp,
    render: renderLastPing,
    sorter: true,
    sortOrder: getSortOrder(ColumnKey.lastTraceTimestamp),
    width: ColumnWidth.lastTraceTimestamp,
  };

  const creationDateColumn = {
    title: i18n.t('TELEMATICS.ACCOUNT.DETAILS.DEVICES.COLUMNS.CONNECTED_DATE'),
    dataIndex: ColumnKey.creationDate,
    key: ColumnKey.creationDate,
    sorter: true,
    sortOrder: getSortOrder(ColumnKey.creationDate),
    width: ColumnWidth.creationDate,
  };

  const actionColumn = ({
    title: i18n.t('TELEMATICS.ACCOUNT.DETAILS.DEVICES.COLUMNS.ACTION'),
    key: ColumnKey.action,
    width: ColumnWidth.action,
  });

  const baseColumns = [deviceIdColumn, vehicleIdColumn, vinColumn, licensePlateCountryCodeColumn,
    licensePlateNumberColumn, vehicleTypeColumn, connectionStatusColumn, providerDeviceIdColumn,
    lastTraceTimestampColumn];

  const resolveColumnsProps = () => {
    switch (deviceContext) {
      case DeviceContext.NEW:
        return [...baseColumns, creationDateColumn, actionColumn];
      case DeviceContext.CONNECTED:
        return [
          ...baseColumns,
          {
            ...creationDateColumn,
            render: renderConnectedDate,
          },
          {
            ...actionColumn,
          },
        ];
      case DeviceContext.DEACTIVATED:
        return [
          ...baseColumns,
          {
            ...creationDateColumn,
            render: renderConnectedDate,
          },
          {
            ...actionColumn,
          },
        ];
      default:
        return <></>;
    }
  };

  const onPageChange = (page, pageSize) => onSetPagination({ page, size: pageSize });

  const handleTableChange = (p, f, sorter, { action }) => {
    if (action === 'sort') {
      onSetSorting(sorter);
    }
  };

  const tableOnRow = (row) => ({
    onClick: (e) => {
      if (isEditMode && !disabledSelectionOnTargetClasses.some(
        (disabled) => e.target.className.includes(disabled) || e.target.parentElement.className.includes(disabled),
      )) {
        rowSelectionProps.onSelect(validateRow)(row);
      }
    },
  });

  const tableRowSelection = (isEditMode && {
    onSelect: rowSelectionProps.onSelect(validateRow),
    onSelectAll: rowSelectionProps.onSelectAll(validateAllRows),
    selectedRowKeys,
  }) || undefined;

  return isTableView ? (
    <>
      <TableWithContent
        tableLayout="fixed"
        columns={resolveColumnsProps()}
        dataSource={rows}
        showSorterTooltip
        rowKey="deviceId"
        size="small"
        bordered
        locale={{
          emptyText: i18n.t(
            'TELEMATICS.ACCOUNT.DETAILS.DEVICES.FETCH.GET_DEVICES_NOT_FOUND_FILTERED',
          ),
        }}
        pagination={{
          showSizeChanger: true,
          showTotal: (total) => (<span>{i18n.t('COMMON.TOTAL', { total })}</span>),
          total: totalElements,
          disabled: loading,
          current: pagination.page,
          pageSize: pagination.size,
          onChange: (page, pageSize) => onPageChange(page, pageSize),
          onShowSizeChange: (page, pageSize) => onPageChange(page, pageSize),
        }}
        onChange={handleTableChange}
        loading={TableLoadingComponent(loading)}
        rowSelection={tableRowSelection}
        onRow={tableOnRow}
      />
    </>
  ) : (
    <>
      <Empty description={i18n.t('TELEMATICS.ACCOUNT.DETAILS.DEVICES.FETCH.GET_DEVICES_NOT_FOUND')} />
    </>
  );
};

DevicesTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  devices: PropTypes.any.isRequired,
  onSetPagination: PropTypes.func.isRequired,
  onSetSorting: PropTypes.func.isRequired,
  filters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.any)),
  updateFilters: PropTypes.func.isRequired,
  viewGpsConnectionDisabled: PropTypes.bool.isRequired,
  isTableView: PropTypes.bool,
  deviceContext: PropTypes.oneOf(Object.values(DeviceContext)).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  vins: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  rows: PropTypes.any.isRequired,
};

DevicesTable.defaultProps = {
  filters: {},
  isTableView: false,
  form: undefined,
  vins: null,
};

export default DevicesTable;
