import { QueryMapper } from '../../../../store/actions/axiosHelpers';
import { DeviceContext } from '../../../../types';
import { ConnectionStatus } from './DevicesContent.constants';

export const FILTERS = {
  VIN: {
    name: 'VIN',
    apiProperty: 'vin',
    queryMapper: [QueryMapper.takeFirst],
  },
  LICENSE_PLATE_NUMBER: {
    name: 'LICENSE_PLATE_NUMBER',
    apiProperty: 'licensePlateNumber',
    queryMapper: [QueryMapper.takeFirst],
  },
  CONNECTION_STATUS: {
    name: 'CONNECTION_STATUS',
    apiProperty: 'connectionStatuses',
  },
  PROVIDER_DEVICE_ID: {
    name: 'PROVIDER_DEVICE_ID',
    apiProperty: 'providerDeviceId',
    queryMapper: [QueryMapper.takeFirst],
  },
};

export const connectionStatusOptions = [
  { label: 'TELEMATICS.ACCOUNT.DETAILS.DEVICES.STATUSES.PENDING.LABEL', value: ConnectionStatus.PENDING },
  {
    label: 'TELEMATICS.ACCOUNT.DETAILS.DEVICES.STATUSES.NEEDS_VERIFICATION.LABEL',
    value: ConnectionStatus.NEEDS_VERIFICATION,
  },
  { label: 'TELEMATICS.ACCOUNT.DETAILS.DEVICES.STATUSES.CONNECTED.LABEL', value: ConnectionStatus.CONNECTED },
  {
    label: 'TELEMATICS.ACCOUNT.DETAILS.DEVICES.STATUSES.CONNECTION_ISSUE.LABEL',
    value: ConnectionStatus.CONNECTION_ISSUE,
  },
  { label: 'TELEMATICS.ACCOUNT.DETAILS.DEVICES.STATUSES.DEACTIVATED.LABEL', value: ConnectionStatus.DEACTIVATED },
];

export const connectionStatusOptionsByContext = (deviceContext) => {
  switch (deviceContext) {
    case DeviceContext.NEW:
      return connectionStatusOptions.filter(({ value }) => [
        ConnectionStatus.PENDING, ConnectionStatus.NEEDS_VERIFICATION,
      ].includes(value));
    case DeviceContext.CONNECTED:
      return connectionStatusOptions.filter(({ value }) => [
        ConnectionStatus.CONNECTED, ConnectionStatus.NEEDS_VERIFICATION, ConnectionStatus.CONNECTION_ISSUE,
      ].includes(value));
    case DeviceContext.DEACTIVATED:
      return connectionStatusOptions.filter(({ value }) => [ConnectionStatus.DEACTIVATED].includes(value));
    default:
      return connectionStatusOptions;
  }
};

export const DevicesColumnKey = {
  deviceId: 'deviceId',
  vehicleId: 'vehicleId',
  vin: 'vin',
  licensePlateCountryCode: 'licensePlateCountryCode',
  licensePlateNumber: 'licensePlateNumber',
  vehicleType: 'vehicleType',
  connectionStatus: 'connectionStatus',
  providerDeviceId: 'providerDeviceId',
  lastTraceTimestamp: 'lastTraceTimestamp',
  creationDate: 'creationDate',
  action: 'action',
};

export const DevicesColumnWidth = {
  vin: 'auto',
  licensePlateCountryCode: 89,
  licensePlateNumber: 'auto',
  vehicleType: 116,
  connectionStatus: 153,
  providerDeviceId: 'auto',
  lastTraceTimestamp: 146,
  creationDate: 133,
  action: 90,
};
