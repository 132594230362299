import { DeviceContext } from '../../../../../types';
import { GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY } from '../../../../actions';
import {
  GET_DEVICES,
  REFRESH_DEVICES,
} from '../../../../actions/telematicAccounts/devices';
import { normalizeTelematicAccountDevices } from '../../../../normalizers';

const initial = {
  fetchStatus: null,
  page: {
    content: [],
  },
  loading: false,
  majorReload: false,
};

export default (deviceContext = DeviceContext.CONNECTED) => (state = initial, { type, payload }) => {
  switch (type) {
    case GET_DEVICES[deviceContext].START:
    case REFRESH_DEVICES[deviceContext].START:
      return {
        ...state,
        fetchStatus: type,
        loading: true,
        majorReload: !payload.executeInBackground,
      };
    case GET_DEVICES[deviceContext].SUCCESS:
    case REFRESH_DEVICES[deviceContext].SUCCESS:
      return {
        ...state,
        fetchStatus: type,
        page: normalizeTelematicAccountDevices(payload.data, deviceContext),
        loading: false,
        majorReload: false,
      };
    case GET_DEVICES[deviceContext].FAIL:
    case REFRESH_DEVICES[deviceContext].FAIL:
      return {
        ...state,
        fetchStatus: type,
        loading: false,
        majorReload: false,
      };
    case GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY.SUCCESS:
      return initial;
    default:
      return state;
  }
};
