import { DeviceContext } from '../../types';

export function normalizeTelematicAccountsFormConfig(configFromApi) {
  return configFromApi.providers.map(({
    providerId, displayName, uiAttributes, assistance, typeOem, forUpdateOnly,
  }) => ({
    providerId,
    displayName,
    providerLogoUrl: uiAttributes?.providerMetadata?.logoUrl,
    fields: uiAttributes?.formAttributes.map(
      ({
        name: fieldName,
        displayNameKey,
        optional,
        notUpdatable,
        options,
        type,
        value,
      }) => ({
        fieldName,
        i18nKey: (displayNameKey ?? fieldName),
        optional: optional ?? false,
        notUpdatable: notUpdatable ?? false,
        options: options || [],
        type,
        value,
      }),
    ) || [],
    assistance: {
      noAssistance: assistance?.noAssistance ?? false,
      instructionLinks: assistance?.instructionLinks || [],
    },
    typeOem,
    forUpdateOnly: forUpdateOnly ?? false,
  }));
}

export function normalizeTelematicAccountsForTable(accountsPayload) {
  return accountsPayload.accounts.map(({
    accountId,
    accountDisplayName,
    accountDescription,
    providerName,
    deviceCount,
    workingStatus,
    hasFormConfiguration,
    hasVins,
  }) => ({
    accountId,
    accountDisplayName,
    accountDescription,
    providerName,
    deviceCount,
    workingStatus,
    hasFormConfiguration,
    hasVins,
  }));
}

export function normalizeTelematicAccount(account) {
  return {
    providerId: account.providerId,
    providerDisplayName: account.providerDisplayName,
    accountId: account.accountId,
    companyId: account.companyId,
    accountDisplayName: account.accountDisplayName,
    accountDescription: account.userDescription,
    credentials: account.credentials,
  };
}

export function normalizeTelematicAccountDetails(currentAccountDetails, newAccountDetails) {
  return {
    creationDate: newAccountDetails.creationDate,
    telematicProvider: {
      id: newAccountDetails.telematicProvider.id,
      displayName: newAccountDetails.telematicProvider.displayName,
    },
    newVehiclesCount: newAccountDetails.newVehiclesCount ?? currentAccountDetails?.newVehiclesCount,
    connectedVehiclesCount: newAccountDetails.connectedVehiclesCount ?? currentAccountDetails?.connectedVehiclesCount,
    connectionIssueVehiclesCount: newAccountDetails.connectionIssueVehiclesCount
      ?? currentAccountDetails?.connectionIssueVehiclesCount,
    deactivatedVehiclesCount: newAccountDetails.deactivatedVehiclesCount
      ?? currentAccountDetails?.deactivatedVehiclesCount,
    credentials: newAccountDetails.credentials,
  };
}

export function normalizeTelematicAccountUpdate(accountUpdate) {
  const account = accountUpdate.telematicAccount;
  return {
    providerId: account.providerId,
    providerDisplayName: account.providerDisplayName,
    accountId: account.accountId,
    companyId: account.companyId,
    accountDisplayName: account.accountDisplayName,
    accountDescription: account.userDescription,
    credentials: account.credentials,
    details: accountUpdate.details,
  };
}

export function normalizeTelematicAccountDevicesContent(response, deviceContext) {
  switch (deviceContext) {
    case DeviceContext.NEW:
      return response.newDevices;
    case DeviceContext.CONNECTED:
      return response.connectedDevices;
    case DeviceContext.DEACTIVATED:
      return response.deactivatedDevices;
    default:
      return null;
  }
}

export function normalizeTelematicAccountDevices(response, deviceContext) {
  return {
    page: response.page,
    size: response.size,
    totalPages: response.totalPages,
    totalElements: response.totalElements,
    content: normalizeTelematicAccountDevicesContent(response, deviceContext),
  };
}
