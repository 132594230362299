import React, { useEffect } from 'react';
import { Button, Row, Tabs } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useDispatch, useSelector } from 'react-redux';
import RoutePath from '../../../RoutePath';
import { FlexRightSpaced, StyledTabs } from '../../../components';
import { getSelectedCompany, getTelematicAccountListForCompany } from '../../../store/selectors';
import { fetchTelematicAccountListForCompany } from '../../../store/actions';
import TelematicAccountListTable from './Components/TelematicAccountListTable';

const TelematicAccountCreationPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const contextCompanyId = useSelector(getSelectedCompany);
  const {
    telematicAccountListForCompany,
    telematicAccountListForCompanyLoading,
  } = useSelector(getTelematicAccountListForCompany);

  useEffect(() => {
    dispatch(fetchTelematicAccountListForCompany(contextCompanyId));
  }, [dispatch, contextCompanyId]);

  const openNewAccountForm = () => {
    history.push(RoutePath.telematicAccountsCreate);
  };

  return (
    <>
      <BreadcrumbsItem to={RoutePath.telematicAccountsView}>{t('BREADCRUMB.TELEMATICS')}</BreadcrumbsItem>
      <Row style={{ marginBottom: '56px' }}>
        <StyledTabs tabBarExtraContent={(
          <FlexRightSpaced>
            <Button
              type="primary"
              style={{ margin: 8 }}
              icon={<PlusOutlined />}
              onClick={openNewAccountForm}
            >
              {t('TELEMATICS.ACCOUNT.BUTTON_NEW_ACCOUNT')}
            </Button>
          </FlexRightSpaced>
        )}
        >
          <Tabs.TabPane tab={t('BREADCRUMB.TELEMATIC_PROVIDER_ACCOUNTS')}>
            <TelematicAccountListTable
              accounts={telematicAccountListForCompany}
              isLoading={telematicAccountListForCompanyLoading}
            />
          </Tabs.TabPane>
        </StyledTabs>
      </Row>
    </>
  );
};

TelematicAccountCreationPage.propTypes = {};

export default TelematicAccountCreationPage;
