/* eslint-disable max-len */
const orders = '/v0/orders';
const tours = '/v0/orders/tour';
// const sharings = '/v9/orders/sharings';
const companiesApi = '/v0/companies';
const companiesApiV1 = '/v1/companies';
const devicesApi = '/v0/devices';
const statisticsApi = '/v0/stats';
const personalizationApi = '/v0/personalizations';
const monitoringOrdersApi = '/v0/monitoring-orders';
const sharedVehiclesApi = '/v0/shared-vehicles';
const vehiclesApi = '/v0/vehicles';
const vehicleApi = '/v0/vehicle';
const invitationApi = '/v0/companies/company';
const requests = 'v1/monitoring-requests';
const ordersStatsPositionApi = '/v0/orders/stats/positions';
const locationSuggesterSearchApi = '/v0/location-suggestions/search';
const locationDetailsApi = '/v0/location-suggestions';
const selfServiceTelematicAccountsApi = '/api/self-service/telematic-accounts';
const providerAccountsApi = '/v0/provider-accounts';

const paramIfExists = (name, val) => (val ? `${name}=${val}` : '');
export default {
  personsMe: () => '/v0/persons/me',

  locationSuggesterSearchApi: () => locationSuggesterSearchApi,
  locationDetailsApi: (locationId) => `${locationDetailsApi}/${locationId}`,
  companies: () => companiesApi,
  companiesSearch: () => `${companiesApi}/search`,
  findCompany: () => '/api/companies/find',
  vehiclesSearch: (companyId) => `${companiesApi}/${companyId}/vehicles/search`,
  companyVehicleRelationExists: () => `${companiesApi}/vehicles/relationExists`,
  vehicleDetails: (companyId, vehicleId, providingCompanyId) => `${companiesApi}/${companyId}/vehicles/${vehicleId}?${paramIfExists('providingCompanyId', providingCompanyId)}`,
  vehicleRemark: (companyId, vehicleId) => `${companiesApi}/${companyId}/vehicles/${vehicleId}/remarks`,
  sharingsSearch: (companyId) => `${companiesApi}/${companyId}/sharings/search`,
  allCompanies: () => `${companiesApiV1}`,
  companyVehicles: (companyId) => `${companiesApiV1}/${companyId}/vehicles`,
  companyVehiclesForPermanentMonitoring: (companyId) => `${companiesApiV1}/${companyId}/vehicles/permanent-monitoring`,

  carriersInvitationSearch: (companyId) => `${invitationApi}/${companyId}/carriers/search`,

  activateDevices: () => `${devicesApi}/activate`,

  creatMonitoringRequest: () => `${requests}/create`,
  requestsOutgoingSearch: (companyId, userId) => `${requests}/outgoing/company/${companyId}/user/${userId}`,
  requestsIncomingSearch: (companyId, userId) => `${requests}/incoming/company/${companyId}/user/${userId}`,

  requestsMonitoringDecline: () => `${requests}/decline`,
  requestsMonitoringAccept: () => `${requests}/accept`,
  requestsMonitoringDeactivate: () => `${requests}/deactivate`,

  requestsDetails: (requestId, companyId) => `${requests}/${requestId}/company/${companyId}`,

  vehicle: () => vehicleApi,
  vehiclePositions: (vehicleId) => `${vehiclesApi}/${vehicleId}/positions`,
  editVehicles: () => `${vehiclesApi}/edit`,

  generateReport: () => '/v0/documents/generate',
  generateMonitoringReport: () => '/v0/documents/generate/monitoring',

  shareVehicle: () => sharedVehiclesApi,
  stopSharingVehicle: () => `${sharedVehiclesApi}/stop`,
  editSharingVehicle: () => `${sharedVehiclesApi}/edit`,

  orders: () => tours,
  updateOrder: (internalId) => `${tours}/${internalId}`,
  emailSharingsSearch: () => `${orders}/sharings`,
  emailSharingsDelete: (orderId) => `${orders}/${orderId}/sharings`,
  // emailSharingsSearch: () => sharings,
  ordersByType: (orderType) => `${tours}/${orderType}`,
  ordersByTypeSearch: (orderType) => `${tours}/${orderType}/search`,
  orderDetails: (internalId) => `${tours}/${internalId}/details`,
  orderDeactivation: (internalId) => `${tours}/${internalId}/deactivation`,
  orderShareToEmail: (orderId) => `${orders}/${orderId}/sharings`,
  // orderShareToEmail: (orderId) => `${orders}/${orderId}/sharings`,
  orderPositions: (internalId) => `${tours}/${internalId}/positions`,
  calculateEta: (id) => `${orders}/${id}/eta`,
  geofencing: (id) => `${orders}/${id}/geofencing`,
  orderCarriers: () => `${monitoringOrdersApi}/carriers`,
  snapshot: (monitoringOrderId) => `${monitoringOrdersApi}/${monitoringOrderId}/snapshot`,
  routePoints: (monitoringOrderId) => `${monitoringOrdersApi}/${monitoringOrderId}/route-points`,

  tableConfigurations: (table) => `${personalizationApi}/tables/${table}/configurations`,
  tableConfiguration: (table, configurationId) => `${personalizationApi}/tables/${table}/configurations/${configurationId}`,
  lastUsedConfiguration: (table) => `${personalizationApi}/tables/${table}/configurations/latest`,
  lastUsedInterfaceConfiguration: (table) => `${personalizationApi}/tables/${table}/interfaces/latest`,
  featureToggles: () => `${personalizationApi}/current/features`,

  tourStatistics: (internalId) => `${statisticsApi}/tours/${internalId}/statistics`,
  vehicleLastTrace: (vehicleId) => `${statisticsApi}/vehicles/${vehicleId}/positions/latest`,
  vehicleAnalysedPositions: (vehicleId) => `${statisticsApi}/vehicles/${vehicleId}/positions`,
  positionsAnalysis: () => `${ordersStatsPositionApi}`,

  providerAccountBase: () => `${providerAccountsApi}`,
  providerAccountsOverview: () => `${providerAccountsApi}/overview`,
  providerAccountById: (accountId) => `${providerAccountsApi}/${accountId}`,
  providerAccountDetails: (accountId) => `${providerAccountsApi}/${accountId}/details`,
  providerAccountNewDevices: (accountId) => `${providerAccountsApi}/${accountId}/new-devices`,
  providerAccountConnectedDevices: (accountId) => `${providerAccountsApi}/${accountId}/connected-devices`,
  providerAccountDeactivatedDevices: (accountId) => `${providerAccountsApi}/${accountId}/deactivated-devices`,
  telematicAccountFormConfig: () => `${selfServiceTelematicAccountsApi}/form-config`,
  telematicAccountUnknownProvider: () => `${selfServiceTelematicAccountsApi}/unknown-provider`,
  telematicAccountSupportRequired: () => `${selfServiceTelematicAccountsApi}/support-required`,
};
