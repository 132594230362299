import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Redirect, useLocation } from 'react-router';
import styled from 'styled-components';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import Orders from './Orders/Orders';
import Error404 from './Error/Error404';
import { FeatureToggle, setTitle } from '../store/actions';
import GlobalModalsWrapper from './GlobalModalsWrapper';
import Carrier from './Carrier/Carrier';
import { areFeatureTogglesFetched } from '../store/selectors';
import { Loader } from '../components';
import FeatureRoute from '../components/Structure/FeatureRoute';
import VehiclesRoutes from './Vehicles/VehiclesRoutes';
import SharingRoutes from './Sharing/SharingRoutes';
import EmailSharingRoutes from './EmailSharing/EmailSharingRoutes';
import MyProfileRoutes from './MyProfile/MyProfileRoutes';
import RequestsRoutes from './Requests/RequestsRoutes';
import TelematicAccounts from './Telematics/TelematicAccounts';

const LayoutContent = styled(Layout.Content)`
  padding: 0 20px;
  background-color: ${(props) => props.theme.color.backgroundLight2};
  overflow-y: auto;
`;

export default () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const featureTogglesFetched = useSelector(areFeatureTogglesFetched);

  useEffect(() => {
    dispatch(setTitle({ path: location.pathname }));
  }, [location.pathname, dispatch]);

  return !featureTogglesFetched ? <Loader text={t('FEATURE_TOGGLES.FETCHING')} />
    : (
      <LayoutContent>
        <Switch>
          <Redirect
            exact
            from="/"
            to="/vehicles"
          />
          {/* Added to prevent users from seeing unknown route after changing routes */}
          <Redirect
            exact
            from="/fleet"
            to="/vehicles"
          />
          <FeatureRoute
            feature={FeatureToggle.DISABLED_FLEET}
            disabledIfPresent
            fallbackRoute="/orders"
            path="/vehicles"
            component={VehiclesRoutes}
          />
          <Route
            path="/sharings"
            component={SharingRoutes}
          />
          <Route
            path="/requests"
            component={RequestsRoutes}
          />
          <Route
            path="/email-sharings"
            component={EmailSharingRoutes}
          />
          <Route
            path="/my-profile"
            component={MyProfileRoutes}
          />
          <Route
            path="/orders"
            component={Orders}
          />
          <Route
            path="/carriers"
            component={Carrier}
          />
          <Route
            component={TelematicAccounts}
          />
          <Route component={Error404} />
        </Switch>
        <GlobalModalsWrapper />
      </LayoutContent>
    );
};
