import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Card, Row, Button,
} from 'antd';
import {
  ApiTwoTone, EditOutlined, EyeOutlined, PlusOutlined,
} from '@ant-design/icons';
import { DeviceContext } from '../../../../types';
import { LanguageService as i18n } from '../../../../services';
import DevicesFormTable from './DevicesFormTable';
import {
  resetDevicesPagination, setDevicesPagination,
} from '../../../../store/actions/telematicAccounts/devices/pagination.actions';
import {
  fetchDevices,
  GET_DEVICES,
  REFRESH_DEVICES,
  setDevicesEditMode,
} from '../../../../store/actions/telematicAccounts/devices';
import {
  devicesEditModeIsEnabled, devicesPrevAction, getDevices, getDevicesFilters, tableNames,
} from '../../../../store/selectors';
import { setDevicesSorting } from '../../../../store/actions/telematicAccounts/devices/sorting.actions';
import {
  clearInterfaceFiltersExcept, fetchLastUsedInterfaceConfiguration, fetchTelematicAccountDetails,
  mergeUpdateInterfaceFilters,
} from '../../../../store/actions';
import {
  FiltersTags, FlexRightSpaced, FullSpace, Loader,
} from '../../../../components';
import RoutePath from '../../../../RoutePath';
import RefreshButton from '../../../../components/Table/ReloadButton';
import { DEVICES_ACTION } from './DevicesContent.constants';
import { setPrevAction } from '../../../../store/actions/telematicAccounts/devices/prevAction.actions';

const DevicesContent = ({
  accountId, viewGpsConnectionDisabled, devicesTabsViewEnabled, accountDetails, deviceContext, setDeviceContext,
  devicesForm,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [prevDeviceContext, setPrevDeviceContext] = useState(null);

  const onTabChange = (key) => {
    dispatch(setPrevAction(DEVICES_ACTION.TAB_CHANGE, key));
    setPrevDeviceContext(deviceContext);
    setDeviceContext(key);
  };

  const tabList = [
    {
      key: DeviceContext.NEW,
      tab: i18n.t('TELEMATICS.ACCOUNT.DETAILS.NEW_VEHICLES'),
    },
    {
      key: DeviceContext.CONNECTED,
      tab: i18n.t('TELEMATICS.ACCOUNT.DETAILS.CONNECTED_VEHICLES'),
    },
    {
      key: DeviceContext.DEACTIVATED,
      tab: i18n.t('TELEMATICS.ACCOUNT.DETAILS.DEACTIVATED_VEHICLES'),
    },
  ];

  const tableName = tableNames[deviceContext];

  const devices = useSelector(getDevices(deviceContext));
  const filters = useSelector(getDevicesFilters(tableName));
  const isEditMode = useSelector(devicesEditModeIsEnabled(deviceContext));
  const prevAction = useSelector(devicesPrevAction(deviceContext));

  const fetchData = useCallback(() => {
    const prevActionName = prevAction?.name;
    if (!prevActionName || prevActionName === DEVICES_ACTION.TAB_CHANGE) {
      dispatch(fetchLastUsedInterfaceConfiguration(tableName))
        .then(() => {
          dispatch(fetchDevices(accountId, deviceContext, !!prevActionName));
        });
    } else {
      dispatch(fetchDevices(accountId, deviceContext, !!prevActionName));
    }
    if (!prevActionName) {
      return;
    }
    let deviceContexts = [deviceContext];
    if (prevActionName === DEVICES_ACTION.SUBMIT_NEW_VEHICLES_FORM_SUCCESS) {
      deviceContexts = [...deviceContexts, DeviceContext.CONNECTED];
    } else if (prevActionName === DEVICES_ACTION.TAB_CHANGE) {
      deviceContexts = [...deviceContexts, prevDeviceContext];
    }
    dispatch(fetchTelematicAccountDetails(accountId, deviceContexts, true));
  }, [accountId, deviceContext, prevDeviceContext, dispatch, prevAction, tableName]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const setPaginationAndRefresh = (newPagination) => {
    dispatch(setDevicesPagination(newPagination, deviceContext));
    dispatch(setPrevAction(DEVICES_ACTION.REFRESH, deviceContext));
  };

  const setSorting = (newSorting) => {
    dispatch(setDevicesSorting(newSorting, deviceContext));
    dispatch(setPrevAction(DEVICES_ACTION.REFRESH, deviceContext));
  };

  const updateFilters = (filter) => dispatch(mergeUpdateInterfaceFilters(tableName, filter));

  const contentList = {
    [DeviceContext.NEW]: <DevicesFormTable
      devices={devices}
      onSetPagination={setPaginationAndRefresh}
      onSetSorting={setSorting}
      filters={filters}
      updateFilters={updateFilters}
      viewGpsConnectionDisabled={viewGpsConnectionDisabled}
      isTableView={!!(accountDetails.newVehiclesCount)}
      deviceContext={deviceContext}
      changeTab={onTabChange}
      form={devicesForm}
      vins={accountDetails.credentials.vins}
    />,
    [DeviceContext.CONNECTED]: <DevicesFormTable
      devices={devices}
      onSetPagination={setPaginationAndRefresh}
      onSetSorting={setSorting}
      filters={filters}
      updateFilters={updateFilters}
      viewGpsConnectionDisabled={viewGpsConnectionDisabled}
      isTableView={!!(accountDetails.connectedVehiclesCount + accountDetails.connectionIssueVehiclesCount)}
      deviceContext={deviceContext}
      form={devicesForm}
    />,
    [DeviceContext.DEACTIVATED]: <DevicesFormTable
      devices={devices}
      onSetPagination={setPaginationAndRefresh}
      onSetSorting={setSorting}
      filters={filters}
      updateFilters={updateFilters}
      viewGpsConnectionDisabled={viewGpsConnectionDisabled}
      isTableView={!!(accountDetails.deactivatedVehiclesCount)}
      deviceContext={deviceContext}
    />,
  };

  const updateFiltersAndRefresh = (filter) => {
    updateFilters(filter);
    dispatch(resetDevicesPagination());
    dispatch(setPrevAction(DEVICES_ACTION.REFRESH, deviceContext));
  };

  const clearFiltersAndRefresh = () => {
    dispatch(clearInterfaceFiltersExcept(tableName));
    dispatch(resetDevicesPagination());
    dispatch(setPrevAction(DEVICES_ACTION.REFRESH, deviceContext));
  };

  const refreshData = () => {
    dispatch(setPrevAction(DEVICES_ACTION.REFRESH, deviceContext));
  };

  const handleClose = (filterName) => {
    updateFiltersAndRefresh({ [filterName]: [] });
  };

  const onEditVehicleDetails = () => {
    if (!isEditMode) {
      dispatch(setDevicesEditMode(deviceContext));
    }
  };

  const renderErrorMessage = () => (
    <FullSpace height="500px">
      <span style={{ fontSize: '20px' }}>{i18n.t('TELEMATICS.ACCOUNT.DETAILS.DEVICES.FETCH.GET_DEVICES_FAIL')}</span>
    </FullSpace>
  );
  const renderLoader = () => (
    <Loader text={i18n.t('TELEMATICS.ACCOUNT.DETAILS.DEVICES.FETCH.GET_DEVICES')} height="50px" />
  );

  const renderTable = () => {
    switch (devices.data.fetchStatus) {
      case GET_DEVICES[deviceContext].START:
        return renderLoader();
      case REFRESH_DEVICES[deviceContext].START:
      case GET_DEVICES[deviceContext].SUCCESS:
      case REFRESH_DEVICES[deviceContext].SUCCESS: {
        return contentList[deviceContext];
      }
      case GET_DEVICES[deviceContext].FAIL:
        return renderErrorMessage();
      default:
        return <></>;
    }
  };

  return devicesTabsViewEnabled ? (
    <Card
      title={(
        <span style={{ fontSize: '16px', fontWeight: '500' }}>
          {i18n.t('TELEMATICS.ACCOUNT.DETAILS.VEHICLE_AND_DEVICE_DETAILS')}
        </span>
      )}
      extra={(
        <FlexRightSpaced>
          <Button
            size="small"
            icon={<EditOutlined />}
            onClick={() => onEditVehicleDetails()}
            disabled={[DeviceContext.NEW, DeviceContext.DEACTIVATED].includes(deviceContext)
              || isEditMode || !contentList[deviceContext].props.isTableView}
          >
            {i18n.t('TELEMATICS.ACCOUNT.DETAILS.EDIT_VEHICLE_DETAILS')}
          </Button>
          {viewGpsConnectionDisabled ? (
            <Button
              type="primary"
              size="small"
              icon={<PlusOutlined />}
              onClick={() => (history.push(RoutePath.telematicAccountDetailsAddVehicles(accountId)))}
            >
              {i18n.t('TELEMATICS.ACCOUNT.DETAILS.ADD_NEW_VEHICLE')}
            </Button>
          ) : (
            <Button
              type="primary"
              size="small"
              icon={<EyeOutlined />}
              onClick={() => (history.push(RoutePath.telematicAccountDetailsView(accountId)))}
            >
              {i18n.t('TELEMATICS.ACCOUNT.DETAILS.VIEW_GPS_CONNECTION')}
            </Button>
          )}
          <RefreshButton onClick={refreshData} compact />
        </FlexRightSpaced>
      )}
      style={{ marginTop: '20px' }}
      tabList={tabList}
      activeTabKey={deviceContext}
      onTabChange={onTabChange}
      tabProps={{
        size: 'small',
      }}
      tabBarExtraContent={(
        <>
          <FiltersTags
            filters={{ ...filters }}
            onRemoveFilter={handleClose}
            onClearFilters={clearFiltersAndRefresh}
          />
        </>
      )}
      bodyStyle={{ padding: '24px' }}
    >
      {renderTable()}
    </Card>
  ) : (
    <Card
      title={(i18n.t('TELEMATICS.ACCOUNT.DETAILS.VEHICLE_AND_DEVICE_DETAILS'))}
      style={{ marginTop: '20px' }}
      bodyStyle={{ padding: '44px 24px' }}
    >
      <>
        <Row justify="center" style={{ marginBottom: '24px' }}>
          <ApiTwoTone style={{ fontSize: '64px', padding: '8px' }} />
        </Row>
        <Row justify="center" style={{ fontSize: '24px' }}>
          {i18n.t('TELEMATICS.ACCOUNT.DETAILS.CONNECTING_NEW_VEHICLES')}
        </Row>
        <Row justify="center" style={{ opacity: '0.45' }}>
          {i18n.t('TELEMATICS.ACCOUNT.DETAILS.PLEASE_CHECK_BACK_LATER')}
        </Row>
      </>
    </Card>
  );
};

DevicesContent.propTypes = {
  accountId: PropTypes.string.isRequired,
  viewGpsConnectionDisabled: PropTypes.bool.isRequired,
  devicesTabsViewEnabled: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  accountDetails: PropTypes.any.isRequired,
  deviceContext: PropTypes.oneOf(Object.values(DeviceContext)).isRequired,
  setDeviceContext: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  devicesForm: PropTypes.any.isRequired,
};

export default DevicesContent;
