import {
  Alert, Button, Col, Form, Row, Spin,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormItem } from '../../../components';
import FormSection from '../../../components/Form/FormSection';
import { SpinnerIcon } from '../../../components/Structure/Loader/Loader';
import RoutePath from '../../../RoutePath';
import { fetchTelematicAccountFormConfig } from '../../../store/actions';
import { getTelematicAccountFormConfig } from '../../../store/selectors';
import { uuidv4 } from '../../../utils/uuid';
import SupportButton, { onClickSupportButton } from './SupportButton';
import { Styles } from './TelematicAccount.styled';
import TelematicAccountDetailsSection from './TelematicAccountDetailsSection';
import TelematicProviderChooserSection from './TelematicProviderChooserSection';
import { TelematicAccountOperationType } from '../../../types/enums/telematicAccountFormViewType.enum';

export default function TelematicAccountForm({
  form,
  submitDispatchFunction,
  telematicAccountId,
  initialValues,
  operationType,
  submitClicked,
}) {
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();
  const { t } = useTranslation();

  const { telematicAccountFormConfig, telematicAccountFormConfigLoading } = useSelector(getTelematicAccountFormConfig);

  useEffect(() => {
    dispatch(fetchTelematicAccountFormConfig());
  }, [dispatch]);

  const isCreateOperation = operationType === TelematicAccountOperationType.CREATE;
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [isDetailsDisabled, setDetailsDisabled] = useState(null);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(!isCreateOperation);
  const [validExistingProviderForm, setValidExistingProviderForm] = useState(false);
  const [validNewProviderForm, setValidNewProviderForm] = useState(false);
  const [editedFields, setEditedFields] = useState(undefined);

  const isActiveButton = (form.getFieldValue('notFromListProviderCheckbox') && isCreateOperation)
    ? validNewProviderForm
    : validExistingProviderForm;

  useEffect(() => {
    if (!selectedProvider || !isCreateOperation) {
      return;
    }
    const selectedProviderHasFields = selectedProvider.fields?.length > 0;
    if (submitButtonDisabled === selectedProviderHasFields) {
      setSubmitButtonDisabled(!selectedProviderHasFields);
    }
  }, [selectedProvider, submitButtonDisabled, isCreateOperation]);

  const resetForm = () => {
    setSubmitButtonDisabled(true);
    setValidExistingProviderForm(false);
  };

  function transformTelematicAccountOrUnknownProviderFormToPayload({
    accountDisplayName,
    accountDescription,
    notFromListProviderCheckbox,
    notFromListProviderName,
    notFromListProviderContactEmail,
    notFromListProviderLink,
    ...otherParams
  }) {
    const payload = notFromListProviderCheckbox ? {
      providerName: notFromListProviderName,
      providerContactEmail: notFromListProviderContactEmail,
      providerWebsiteLink: notFromListProviderLink,
    } : {
      accountDescription,
      providerId: selectedProvider.providerId,
      formAttributes: otherParams,
    };

    return {
      payload,
      isUnknownProvider: notFromListProviderCheckbox,
      editedFields,
      resetForm,
      handleUserNeedsSupport: () => onClickSupportButton(telematicAccountId, form, store, history),
    };
  }

  const handleSubmit = () => {
    form.validateFields()
      .then(transformTelematicAccountOrUnknownProviderFormToPayload)
      .then(submitDispatchFunction);
  };

  const resetAccountDetailsFields = (provider) => {
    provider.fields.forEach((field) => {
      form.resetFields([field.fieldName]);
    });
    setValidExistingProviderForm(false);
  };

  const validateNewProvider = () => {
    const newProviderFields = ['notFromListProviderName', 'notFromListProviderContactEmail', 'notFromListProviderLink'];
    if (form.isFieldsTouched(newProviderFields, true)) {
      form.validateFields()
        .then(() => {
          setValidNewProviderForm(true);
        }).catch(() => {
          setValidNewProviderForm(false);
        });
    } else {
      setValidNewProviderForm(false);
    }
  };

  const selectedProviderRequiredFieldsTouched = () => form.isFieldsTouched(['selectedProvider'], true)
    && selectedProvider?.fields.every((field) => form.getFieldValue(field.fieldName) || field.optional);

  const selectedProviderAnyFieldEdited = () => {
    const descriptionEdited = form.getFieldValue('accountDescription') !== (initialValues.accountDescription || '');
    const credentialsEdited = selectedProvider?.fields
      .filter(({ notUpdatable }) => !notUpdatable)
      .some(({ fieldName }) => form.getFieldValue(fieldName) !== initialValues[fieldName]);
    const anyFieldEdited = descriptionEdited || credentialsEdited;
    if (submitButtonDisabled === anyFieldEdited) {
      setSubmitButtonDisabled(!anyFieldEdited);
      setEditedFields({ description: descriptionEdited, credentials: credentialsEdited });
    }
    return anyFieldEdited;
  };

  const validateExistingProvider = () => {
    const canValidateFields = isCreateOperation
      ? selectedProviderRequiredFieldsTouched()
      : selectedProviderAnyFieldEdited();
    if (canValidateFields) {
      form.validateFields()
        .then(() => {
          setValidExistingProviderForm(true);
        }).catch(() => {
          setValidExistingProviderForm(false);
        });
    } else {
      setValidExistingProviderForm(false);
    }
  };

  const validateForm = (event) => {
    if (event?.target?.id === 'notFromListProviderCheckbox') {
      return;
    }
    if (form.getFieldValue('notFromListProviderCheckbox') && isCreateOperation) {
      validateNewProvider();
    } else {
      validateExistingProvider(selectedProvider);
    }
  };

  const renderSingleInstructionLink = ({ lang, url }) => {
    const staticLabel = t('TELEMATICS.ACCOUNT.CREATE.SECTIONS.ACCOUNT.INSTRUCTION_LINK_LABEL');
    const linkText = `${lang} ${staticLabel}`;
    return (
      <li key={uuidv4()}>
        <a href={url} target="_blank" rel="noopener noreferrer">{linkText}</a>
      </li>
    );
  };
  const renderIntructionLinks = (links) => links
    .sort((a, b) => a.lang.localeCompare(b.lang))
    .map(renderSingleInstructionLink);

  const renderDetailsBlueBoxDescription = () => {
    const links = selectedProvider.assistance.instructionLinks;
    const hasLinks = links.length > 0;
    const descriptionKey = hasLinks
      ? 'DESCRIPTION_FOR_LINKS'
      : 'DESCRIPTION_NO_LINKS';
    return (
      <>
        {hasLinks && <p>{t('TELEMATICS.ACCOUNT.CREATE.SECTIONS.ACCOUNT.INSTRUCTION_LINKS_HEADER')}</p>}
        <ul>{renderIntructionLinks(links)}</ul>
        <p>{t(`TELEMATICS.ACCOUNT.CREATE.SECTIONS.ACCOUNT.${descriptionKey}`)}</p>
      </>
    );
  };

  return (
    <>
      <Spin
        spinning={submitClicked}
        style={{ maxHeight: '100%' }}
        indicator={(
          <SpinnerIcon size="m" />
        )}
      >
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          form={form}
          style={{ marginTop: '12px', marginBottom: '40px', width: '1100px' }}
          layout="vertical"
          disabled={telematicAccountFormConfigLoading}
          onChange={(event) => validateForm(event)}
        >
          <FormSection title={t(`TELEMATICS.ACCOUNT.${operationType}.SECTIONS.PROVIDER.TITLE`)}>
            <Row justify="left" align="top">
              <TelematicProviderChooserSection
                providers={telematicAccountFormConfig}
                initialProviderId={initialValues.selectedProvider}
                selectedProvider={selectedProvider}
                setSelectedProvider={setSelectedProvider}
                setDetailsDisabled={setDetailsDisabled}
                creationView={isCreateOperation}
                resetAccountDetailsFields={resetAccountDetailsFields}
              />
            </Row>
          </FormSection>

          {!isDetailsDisabled && selectedProvider ? (
            <FormSection title={t('TELEMATICS.ACCOUNT.CREATE.SECTIONS.ACCOUNT.TITLE')}>
              <Row justify="left" align="top">
                <Col span={11}>
                  {selectedProvider?.fields?.length > 0 ? (
                    <TelematicAccountDetailsSection
                      onSelectChange={(value) => validateForm(value)}
                      selectedProvider={selectedProvider}
                      isDetailsDisabled={isDetailsDisabled}
                      creationView={isCreateOperation}
                    />
                  ) : (
                    <>
                      <Alert
                        type="info"
                        showIcon
                        description={t('TELEMATICS.ACCOUNT.CREATE.HAS_NO_ATTRIBUTES.INFO')}
                        style={Styles.wide}
                      />
                      <div style={{ marginTop: '16px' }}>
                        <FormItem
                          key={uuidv4()}
                          label={t('TELEMATICS.ACCOUNT.CREATE.HAS_NO_ATTRIBUTES.INPUT_LABEL')}
                          name="supportInfo"
                          style={Styles.narrow}
                        >
                          <TextArea style={Styles.wide} />
                        </FormItem>
                      </div>
                    </>
                  )}
                </Col>

                {selectedProvider.assistance.noAssistance
                  ? (
                    <>
                    </>
                  ) : (
                    <Col span={13}>
                      <Row justify="end" align="top">
                        <Alert
                          type="info"
                          showIcon
                          message={
                            <b>{t('TELEMATICS.ACCOUNT.CREATE.SECTIONS.ACCOUNT.MESSAGE')}</b>
                          }
                          description={renderDetailsBlueBoxDescription()}
                        />
                      </Row>
                    </Col>
                  )}
              </Row>
            </FormSection>
          )
            : <></>}

          <Row align="top" style={{ marginTop: '16px' }}>
            <Col span={11} style={Styles.wide}>
              <Row>
                <Col span={10}>
                  {!isDetailsDisabled && (
                    <Row justify="start">
                      <SupportButton telematicAccountId={telematicAccountId} form={form} />
                    </Row>
                  )}
                </Col>
                <Col span={14}>
                  <Row justify="end">
                    {!isCreateOperation ? (
                      <Button
                        type="link"
                        style={{ marginRight: '8px' }}
                        onClick={() => (history.push(RoutePath.telematicAccountDetails(telematicAccountId)))}
                      >
                        {t('TELEMATICS.ACCOUNT.UPDATE.BUTTON_RETURN_TO_VEHICLE_DETAILS')}
                      </Button>
                    ) : (
                      <Button
                        type="link"
                        style={{ marginRight: '8px' }}
                        onClick={() => (history.push(RoutePath.telematicAccountsView))}
                      >
                        {t('COMMON.CANCEL')}
                      </Button>
                    )}
                    <Button
                      type="primary"
                      disabled={submitButtonDisabled}
                      onClick={handleSubmit}
                      style={isActiveButton
                        ? { background: '#1890ff', borderColor: '#1890ff' }
                        : { background: '#BDBDBD', borderColor: '#BDBDBD' }}
                    >
                      {t(operationType === TelematicAccountOperationType.UPDATE ? 'COMMON.SAVE' : 'COMMON.SUBMIT')}
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
}

TelematicAccountForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.any.isRequired,
  submitDispatchFunction: PropTypes.func.isRequired,
  telematicAccountId: PropTypes.string,
  initialValues: PropTypes.shape({
    accountDisplayName: PropTypes.string,
    accountDescription: PropTypes.string,
    selectedProvider: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    credentials: PropTypes.any,
  }),
  operationType: PropTypes.oneOf(Object.values(TelematicAccountOperationType)).isRequired,
  submitClicked: PropTypes.bool.isRequired,
};

TelematicAccountForm.defaultProps = {
  telematicAccountId: undefined,
  initialValues: {},
};
