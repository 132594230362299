import React from 'react';
import { Button, Row, Tooltip } from 'antd';
import { EditOutlined, StopOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import RoutePath from '../../../../RoutePath';

export default function TelematicAccountListTableActions({
  accountId, editDisabled, deactivateDisabled, addVehiclesEnabled,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <Row justify="center">
        <Tooltip
          placement="leftBottom"
          title={t(`TELEMATICS.ACCOUNT.OVERVIEW.ACTION_TOOLTIPS.EDIT.${editDisabled ? 'DISABLED' : 'ENABLED'}`)}
        >
          <Button
            type="link"
            disabled={editDisabled}
            icon={<EditOutlined />}
            onClick={() => history.push(addVehiclesEnabled
              ? RoutePath.telematicAccountDetailsAddVehicles(accountId)
              : RoutePath.telematicAccountDetailsView(accountId))}
          />
        </Tooltip>
        <Tooltip
          placement="leftBottom"
          title={t('TELEMATICS.ACCOUNT.OVERVIEW.ACTION_TOOLTIPS.DISABLE')}
        >
          <Button
            type="link"
            disabled={deactivateDisabled}
            icon={<StopOutlined />}
          />
        </Tooltip>
      </Row>
    </>
  );
}

TelematicAccountListTableActions.propTypes = {
  accountId: PropTypes.string.isRequired,
  editDisabled: PropTypes.bool,
  deactivateDisabled: PropTypes.bool,
  addVehiclesEnabled: PropTypes.bool,
};

TelematicAccountListTableActions.defaultProps = {
  editDisabled: false,
  deactivateDisabled: true,
  addVehiclesEnabled: false,
};
