import { DeviceContext } from '../../../../types';
import { getDevicesContent } from '../../../selectors';

const SET_NEW_DEVICES_EDIT_MODE = 'SET_NEW_DEVICES_EDIT_MODE';
const SET_CONNECTED_DEVICES_EDIT_MODE = 'SET_CONNECTED_DEVICES_EDIT_MODE';
const SET_DEACTIVATED_DEVICES_EDIT_MODE = 'SET_DEACTIVATED_DEVICES_EDIT_MODE';
const UPDATE_NEW_DEVICES_EDIT_MODE = 'UPDATE_NEW_DEVICES_EDIT_MODE';
const UPDATE_CONNECTED_DEVICES_EDIT_MODE = 'UPDATE_CONNECTED_DEVICES_EDIT_MODE';
const UPDATE_DEACTIVATED_DEVICES_EDIT_MODE = 'UPDATE_DEACTIVATED_DEVICES_EDIT_MODE';
const NEW_DEVICES_SELECT_ALL = 'NEW_DEVICES_SELECT_ALL';
const CONNECTED_DEVICES_SELECT_ALL = 'CONNECTED_DEVICES_SELECT_ALL';
const DEACTIVATED_DEVICES_SELECT_ALL = 'DEACTIVATED_DEVICES_SELECT_ALL';
const NEW_DEVICES_UNSELECT_ALL = 'NEW_DEVICES_UNSELECT_ALL';
const CONNECTED_DEVICES_UNSELECT_ALL = 'CONNECTED_DEVICES_UNSELECT_ALL';
const DEACTIVATED_DEVICES_UNSELECT_ALL = 'DEACTIVATED_DEVICES_UNSELECT_ALL';
const NEW_DEVICES_APPEND_SELECTED_ROW = 'NEW_DEVICES_APPEND_SELECTED_ROW';
const CONNECTED_DEVICES_APPEND_SELECTED_ROW = 'CONNECTED_DEVICES_APPEND_SELECTED_ROW';
const DEACTIVATED_DEVICES_APPEND_SELECTED_ROW = 'DEACTIVATED_DEVICES_APPEND_SELECTED_ROW';
const NEW_DEVICES_UNSELECT_ROW = 'NEW_DEVICES_UNSELECT_ROW';
const CONNECTED_DEVICES_UNSELECT_ROW = 'CONNECTED_DEVICES_UNSELECT_ROW';
const DEACTIVATED_DEVICES_UNSELECT_ROW = 'DEACTIVATED_DEVICES_UNSELECT_ROW';
const NEW_DEVICES_ADD_ROW_ERROR = 'NEW_DEVICES_ADD_ROW_ERROR';
const CONNECTED_DEVICES_ADD_ROW_ERROR = 'CONNECTED_DEVICES_ADD_ROW_ERROR';
const DEACTIVATED_DEVICES_ADD_ROW_ERROR = 'DEACTIVATED_DEVICES_ADD_ROW_ERROR';
const NEW_DEVICES_ADD_ALL_ROW_ERROR = 'NEW_DEVICES_ADD_ALL_ROW_ERROR';
const CONNECTED_DEVICES_ADD_ALL_ROW_ERROR = 'CONNECTED_DEVICES_ADD_ALL_ROW_ERROR';
const DEACTIVATED_DEVICES_ADD_ALL_ROW_ERROR = 'DEACTIVATED_DEVICES_ADD_ALL_ROW_ERROR';
const NEW_DEVICES_REMOVE_ROW_ERRORS = 'NEW_DEVICES_REMOVE_ROW_ERRORS';
const CONNECTED_DEVICES_REMOVE_ROW_ERRORS = 'CONNECTED_DEVICES_REMOVE_ROW_ERRORS';
const DEACTIVATED_DEVICES_REMOVE_ROW_ERRORS = 'DEACTIVATED_DEVICES_REMOVE_ROW_ERRORS';
const NEW_DEVICES_REMOVE_ALL_ROW_ERRORS = 'NEW_DEVICES_REMOVE_ALL_ROW_ERRORS';
const CONNECTED_DEVICES_REMOVE_ALL_ROW_ERRORS = 'CONNECTED_DEVICES_REMOVE_ALL_ROW_ERRORS';
const DEACTIVATED_DEVICES_REMOVE_ALL_ROW_ERRORS = 'DEACTIVATED_DEVICES_REMOVE_ALL_ROW_ERRORS';

export const SET_DEVICES_EDIT_MODE = {
  [DeviceContext.NEW]: SET_NEW_DEVICES_EDIT_MODE,
  [DeviceContext.CONNECTED]: SET_CONNECTED_DEVICES_EDIT_MODE,
  [DeviceContext.DEACTIVATED]: SET_DEACTIVATED_DEVICES_EDIT_MODE,
};

export const UPDATE_DEVICES_EDIT_MODE = {
  [DeviceContext.NEW]: UPDATE_NEW_DEVICES_EDIT_MODE,
  [DeviceContext.CONNECTED]: UPDATE_CONNECTED_DEVICES_EDIT_MODE,
  [DeviceContext.DEACTIVATED]: UPDATE_DEACTIVATED_DEVICES_EDIT_MODE,
};

export const EXIT_CONNECTED_DEVICES_EDIT_MODE = 'EXIT_CONNECTED_DEVICES_EDIT_MODE';

export const DEVICES_SELECT_ALL = {
  [DeviceContext.NEW]: NEW_DEVICES_SELECT_ALL,
  [DeviceContext.CONNECTED]: CONNECTED_DEVICES_SELECT_ALL,
  [DeviceContext.DEACTIVATED]: DEACTIVATED_DEVICES_SELECT_ALL,
};

export const DEVICES_UNSELECT_ALL = {
  [DeviceContext.NEW]: NEW_DEVICES_UNSELECT_ALL,
  [DeviceContext.CONNECTED]: CONNECTED_DEVICES_UNSELECT_ALL,
  [DeviceContext.DEACTIVATED]: DEACTIVATED_DEVICES_UNSELECT_ALL,
};

export const DEVICES_APPEND_SELECTED_ROW = {
  [DeviceContext.NEW]: NEW_DEVICES_APPEND_SELECTED_ROW,
  [DeviceContext.CONNECTED]: CONNECTED_DEVICES_APPEND_SELECTED_ROW,
  [DeviceContext.DEACTIVATED]: DEACTIVATED_DEVICES_APPEND_SELECTED_ROW,
};

export const DEVICES_UNSELECT_ROW = {
  [DeviceContext.NEW]: NEW_DEVICES_UNSELECT_ROW,
  [DeviceContext.CONNECTED]: CONNECTED_DEVICES_UNSELECT_ROW,
  [DeviceContext.DEACTIVATED]: DEACTIVATED_DEVICES_UNSELECT_ROW,
};

export const DEVICES_ADD_ROW_ERROR = {
  [DeviceContext.NEW]: NEW_DEVICES_ADD_ROW_ERROR,
  [DeviceContext.CONNECTED]: CONNECTED_DEVICES_ADD_ROW_ERROR,
  [DeviceContext.DEACTIVATED]: DEACTIVATED_DEVICES_ADD_ROW_ERROR,
};

export const DEVICES_ADD_ALL_ROW_ERROR = {
  [DeviceContext.NEW]: NEW_DEVICES_ADD_ALL_ROW_ERROR,
  [DeviceContext.CONNECTED]: CONNECTED_DEVICES_ADD_ALL_ROW_ERROR,
  [DeviceContext.DEACTIVATED]: DEACTIVATED_DEVICES_ADD_ALL_ROW_ERROR,
};

export const DEVICES_REMOVE_ROW_ERRORS = {
  [DeviceContext.NEW]: NEW_DEVICES_REMOVE_ROW_ERRORS,
  [DeviceContext.CONNECTED]: CONNECTED_DEVICES_REMOVE_ROW_ERRORS,
  [DeviceContext.DEACTIVATED]: DEACTIVATED_DEVICES_REMOVE_ROW_ERRORS,
};

export const DEVICES_REMOVE_ALL_ROW_ERRORS = {
  [DeviceContext.NEW]: NEW_DEVICES_REMOVE_ALL_ROW_ERRORS,
  [DeviceContext.CONNECTED]: CONNECTED_DEVICES_REMOVE_ALL_ROW_ERRORS,
  [DeviceContext.DEACTIVATED]: DEACTIVATED_DEVICES_REMOVE_ALL_ROW_ERRORS,
};

const appendSelectedRow = (deviceContext, row) => ({
  type: DEVICES_APPEND_SELECTED_ROW[deviceContext],
  payload: {
    row,
  },
});

const selectRows = (deviceContext, rows) => ({
  type: DEVICES_SELECT_ALL[deviceContext],
  payload: {
    rows,
  },
});

const selectAll = (deviceContext, validateAllRows) => (dispatch, getState) => {
  const state = getState();
  const devices = getDevicesContent(deviceContext)(state);
  validateAllRows();
  dispatch(selectRows(deviceContext, devices));
};

const unselectRows = (deviceContext) => ({
  type: DEVICES_UNSELECT_ALL[deviceContext],
});

const unselectAll = (deviceContext, validateAllRows) => (dispatch) => {
  validateAllRows(true);
  dispatch(unselectRows(deviceContext));
};

const unselectRow = (deviceContext, row) => ({
  type: DEVICES_UNSELECT_ROW[deviceContext],
  payload: {
    row,
  },
});

const rowSelectionChange = (deviceContext, validateRow, row) => (dispatch) => {
  validateRow(row.deviceId);

  if (!row.isSelected) {
    dispatch(appendSelectedRow(deviceContext, row));
  } else {
    dispatch(unselectRow(deviceContext, row));
  }
};

const addError = (deviceContext, row, error) => ({
  type: DEVICES_ADD_ROW_ERROR[deviceContext],
  payload: {
    row,
    error,
  },
});

const addAllErrors = (deviceContext, rows) => ({
  type: DEVICES_ADD_ALL_ROW_ERROR[deviceContext],
  payload: {
    rows,
  },
});

const removeErrors = (deviceContext, row, errors) => ({
  type: DEVICES_REMOVE_ROW_ERRORS[deviceContext],
  payload: {
    row,
    errors,
  },
});

const removeAllError = (deviceContext, row) => ({
  type: DEVICES_REMOVE_ALL_ROW_ERRORS[deviceContext],
  payload: {
    row,
  },
});

export const addRowError = (deviceContext, row, error) => (dispatch) => {
  dispatch(addError(deviceContext, row, error));
};

export const addAllRowErrors = (deviceContext, rows) => (dispatch) => {
  dispatch(addAllErrors(deviceContext, rows));
};

export const removeRowErrors = (deviceContext, row, errors) => (dispatch) => {
  dispatch(removeErrors(deviceContext, row, errors));
};

export const removeAllRowError = (deviceContext, row) => (dispatch) => {
  dispatch(removeAllError(deviceContext, row));
};

export const setDevicesEditMode = (deviceContext) => (dispatch, getState) => {
  const state = getState();
  const devices = getDevicesContent(deviceContext)(state);
  const rows = devices.map((d) => ({ isSelected: true, errors: [], ...d }));
  dispatch({
    type: SET_DEVICES_EDIT_MODE[deviceContext],
    payload: {
      rows,
      props: {
        onSelect: (validateRow) => (row) => dispatch(rowSelectionChange(deviceContext, validateRow, row)),
        onSelectAll: (validateAllRows) => (isSelect) => dispatch(
          isSelect ? selectAll(deviceContext, validateAllRows) : unselectAll(deviceContext, validateAllRows),
        ),
      },
    },
  });
};

export const updateDevicesEditMode = (deviceContext) => (dispatch, getState) => {
  const state = getState();
  const devices = getDevicesContent(deviceContext)(state);
  const rows = devices.map((d) => ({ isSelected: true, errors: [], ...d }));
  dispatch({
    type: UPDATE_DEVICES_EDIT_MODE[deviceContext],
    payload: {
      rows,
    },
  });
};

export const exitConnectedDevicesEditMode = () => ({
  type: EXIT_CONNECTED_DEVICES_EDIT_MODE,
});
