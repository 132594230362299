import { combineReducers } from 'redux';
import accountFormConfig from './accountFormConfig.reducer';
import telematicAccountListForCompany from './accountList.reducer';
import telematicAccountForEdit from './accountGet.reducer';
import telematicAccount from './telematicAccount';

export default combineReducers({
  accountFormConfig,
  telematicAccountListForCompany,
  telematicAccountForEdit,
  telematicAccount,
});
