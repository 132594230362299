import SubMenu from 'antd/es/menu/SubMenu';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ApiOutlined, FileSearchOutlined, LogoutOutlined, ScheduleOutlined,
} from '@ant-design/icons';
import { Row, Tooltip } from 'antd';
import { useLocation } from 'react-router';
import RoutePath from '../../../RoutePath';
import UserMenu from './UserMenu';
import { TruckIcon } from '../../Styled/Icons';
import {
  ImageWrapper,
  LayoutSider,
  Logo,
  LogoutButton,
  LogoutDivider,
  MenuItem,
  MenuItemTitle,
  SiderContent,
  StyledMenu,
} from './SidePanel.styled';
import { getMe, isFeatureEnabled } from '../../../store/selectors';
import { FeatureToggle } from '../../../store/actions';
import { UserService } from '../../../services';

const SidePanel = ({ theme }) => {
  const { t } = useTranslation();
  const { data } = useSelector(getMe);
  const assetsSearchEnabled = useSelector(isFeatureEnabled(FeatureToggle.SEARCH_ASSETS));
  const fleetDisabled = useSelector(isFeatureEnabled(FeatureToggle.DISABLED_FLEET));
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(location.pathname);

  const defaultKey = 'vehicles';
  const defaultSubMenu = 'sub1';
  const [openKeys, setOpenKeys] = useState([defaultSubMenu]);

  useEffect(() => {
    const segments = location.pathname.split('/');
    const selectedKeys = [];
    let key = '';

    segments.shift();
    segments.forEach((segment) => {
      key += `${segment}/`;
      selectedKeys.push(key.substring(0, key.length - 1));
    });

    setSelectedKey(selectedKeys);
  }, [location.pathname]);

  return (
    <LayoutSider theme={theme}>
      <SiderContent>
        <div>
          <ImageWrapper>
            <Link to="/vehicles">
              <Logo
                alt="CO3 logo"
                src="./images/logo-simple-light.png"
              />
            </Link>
          </ImageWrapper>
          <StyledMenu
            defaultSelectedKeys={[defaultKey]}
            selectedKeys={selectedKey}
            mode="inline"
            theme={theme}
            openKeys={openKeys}
            onOpenChange={(keys) => {
              const subMenus = keys.filter((key) => key.startsWith('sub'));
              if (subMenus.length === 1) {
                setOpenKeys(subMenus);
              } else {
                setOpenKeys(subMenus.filter((key) => openKeys.indexOf(key) === -1));
              }
            }}
          >
            <SubMenu
              key="sub1"
              title={t('BREADCRUMB.FLEET')}
              icon={(
                <TruckIcon
                  iconStyle={{
                    fontSize: '18px',
                    marginBottom: '-2px',
                  }}
                  style={{
                    lineHeight: '46px',
                  }}
                />
              )}
            >
              {!fleetDisabled && (
                <MenuItem key="vehicles">
                  <Link to="/vehicles">
                    <MenuItemTitle>{t('BREADCRUMB.FLEET_OVERVIEW')}</MenuItemTitle>
                  </Link>
                </MenuItem>
              )}
              <MenuItem key="sharings">
                <Link to="/sharings">
                  <MenuItemTitle>
                    <span style={{ display: 'inline-block', lineHeight: '16px', transform: 'translate(0px, 5px)' }}>
                      {t('BREADCRUMB.FLEET_SHARINGS')}
                    </span>
                  </MenuItemTitle>
                </Link>
              </MenuItem>
            </SubMenu>

            <SubMenu
              key="sub2"
              title={t('BREADCRUMB.MONITORINGS')}
              icon={<ScheduleOutlined style={{ lineHeight: '46px' }} />}
            >
              <MenuItem key="orders/outgoing">
                <Link to="/orders/outgoing/time">
                  <MenuItemTitle>
                    {t('BREADCRUMB.OUTGOING')}
                  </MenuItemTitle>
                </Link>
              </MenuItem>
              <MenuItem key="orders/incoming">
                <Link to="/orders/incoming/time">
                  <MenuItemTitle>
                    {t('BREADCRUMB.INCOMING')}
                  </MenuItemTitle>
                </Link>
              </MenuItem>
              <MenuItem key="requests">
                <Link to="/requests">
                  <MenuItemTitle>
                    {t('BREADCRUMB.REQUESTS')}
                  </MenuItemTitle>
                </Link>
              </MenuItem>
              <MenuItem key="email-sharings">
                <Link to="/email-sharings">
                  <MenuItemTitle>
                    <span style={{ display: 'inline-block', lineHeight: '16px', transform: 'translate(0px, 5px)' }}>
                      {t('BREADCRUMB.SHARINGS')}
                    </span>
                  </MenuItemTitle>
                </Link>
              </MenuItem>
            </SubMenu>

            <MenuItem key="telematic-accounts">
              <Link to={RoutePath.telematicAccountsView}>
                <ApiOutlined style={{ lineHeight: '46px' }} />
                <MenuItemTitle>{t('BREADCRUMB.TELEMATICS')}</MenuItemTitle>
              </Link>
            </MenuItem>

            {assetsSearchEnabled && (
              <MenuItem key="carriers">
                <Link to="/carriers">
                  <FileSearchOutlined style={{ lineHeight: '46px' }} />
                  <MenuItemTitle>
                    {t('BREADCRUMB.CARRIERS')}
                  </MenuItemTitle>
                </Link>
              </MenuItem>
            )}
          </StyledMenu>
        </div>
        <div>
          <Row justify="center">
            <UserMenu user={data} />
          </Row>
          <LogoutDivider type="horizontal" />
          <Row justify="center">
            <Tooltip title={t('USER.LOGOUT')} placement="right">
              <LogoutButton
                icon={<LogoutOutlined />}
                type="link"
                size="large"
                onClick={UserService.doLogout}
              />
            </Tooltip>
          </Row>
        </div>
      </SiderContent>
    </LayoutSider>
  );
};

SidePanel.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
};

SidePanel.defaultProps = {
  theme: 'light',
};

export default SidePanel;
