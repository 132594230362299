/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col } from 'antd';
import EmailSharesTableComponent from './Components/EmailSharesTable/EmailSharesTableComponent';
import { FullHeightRow } from '../../components';
import { fetchEmailShares } from '../../store/actions/emailsharing';

class EmailSharingComponent extends React.Component {
  componentDidMount() {
    const { fetchShares } = this.props;
    fetchShares(false);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { fetchShares } = this.props;
    fetchShares(false);
  }

  render() {
    const { size } = this.props;
    return (
      <FullHeightRow gutter={[10, 10]} style={{ paddingTop: '10px', marginBottom: '56px' }}>
        <Col span={24}>
          <EmailSharesTableComponent
            size={size}
          />
        </Col>
      </FullHeightRow>
    );
  }
}

EmailSharingComponent.propTypes = {
  fetchShares: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

EmailSharingComponent.defaultProps = {
  size: 'small',
};

const actionCreators = {
  fetchShares: fetchEmailShares,
};

export default connect(null, actionCreators)(EmailSharingComponent);
