import React from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { Button, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { LanguageService as i18n } from '../../services';

const StyledButton = styled(Button)`
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
  margin-left: ${(props) => (props.compact ? '10px !important' : '12px')};
  padding-top: 0;
`;

const RefreshButton = ({
  size, loading, onClick, lastRefreshed, compact,
}) => {
  const renderLastRefreshedTooltip = () => (
    lastRefreshed ? i18n.t('VEHICLE.LAST_REFRESHED', { refreshedAt: lastRefreshed.format('HH:mm') }) : null
  );
  return (
    <Tooltip
      placement="bottomLeft"
      title={renderLastRefreshedTooltip()}
    >
      <StyledButton
        size={compact ? 'medium' : size}
        type={compact ? 'link' : null}
        icon={<ReloadOutlined />}
        loading={loading}
        onClick={onClick}
      >
        {compact ? null : i18n.t('COMMON.REFRESH_DATA')}
      </StyledButton>
    </Tooltip>
  );
};

RefreshButton.propTypes = {
  size: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  lastRefreshed: MomentPropTypes.momentObj,
  compact: PropTypes.bool,
};

RefreshButton.defaultProps = {
  size: 'small',
  loading: false,
  lastRefreshed: null,
  compact: false,
};

export default RefreshButton;
