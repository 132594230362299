import React from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import NOP from '../../utils/NOP';
import RefreshButton from './ReloadButton';

const RefreshComponent = ({
  loading, onRefresh, lastRefreshed, size, refreshIntervalSeconds, onUpdateRefreshIntervalSeconds, compact,
  disableRefreshSelection,
}) => {
  const { t } = useTranslation();

  const refreshIntervalOptions = [
    { value: 0, label: t('COMMON.OFF') },
    { value: 30, label: t('COMMON.TIME.SEC_AMOUNT', { amount: 30 }) },
    { value: 60, label: t('COMMON.TIME.MINUTES_AMOUNT', { amount: 1 }) },
    { value: 120, label: t('COMMON.TIME.MINUTES_AMOUNT', { amount: 2 }) },
    { value: 300, label: t('COMMON.TIME.MINUTES_AMOUNT', { amount: 5 }) },
    { value: 600, label: t('COMMON.TIME.MINUTES_AMOUNT', { amount: 10 }) },
    { value: 1800, label: t('COMMON.TIME.MINUTES_AMOUNT', { amount: 30 }) },
    { value: 3600, label: t('COMMON.TIME.HOURS_AMOUNT', { amount: 1 }) },
  ];

  const renderRefreshButton = () => (
    <RefreshButton
      size={size}
      loading={loading}
      onClick={onRefresh}
      lastRefreshed={lastRefreshed}
      compact={compact}
    />
  );

  return (
    <>
      {!compact && renderRefreshButton()}
      <Tooltip placement="left" title={t('COMMON.SELECT_REFRESH_INTERVAL')}>
        <Select
          style={{ minWidth: '72px' }}
          disabled={loading || disableRefreshSelection}
          size={size}
          value={refreshIntervalSeconds}
          onChange={onUpdateRefreshIntervalSeconds}
          options={refreshIntervalOptions}
        />
      </Tooltip>
      {compact && renderRefreshButton()}
    </>
  );
};

RefreshComponent.propTypes = {
  loading: PropTypes.bool,
  onRefresh: PropTypes.func.isRequired,
  lastRefreshed: MomentPropTypes.momentObj,
  size: PropTypes.string,
  compact: PropTypes.bool,
  refreshIntervalSeconds: PropTypes.number.isRequired,
  onUpdateRefreshIntervalSeconds: PropTypes.func,
  disableRefreshSelection: PropTypes.bool,
};

RefreshComponent.defaultProps = {
  size: 'small',
  compact: false,
  loading: false,
  lastRefreshed: null,
  onUpdateRefreshIntervalSeconds: NOP,
  disableRefreshSelection: false,
};

export default RefreshComponent;
