import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import {
  Card, Row, Col, Form,
} from 'antd';
import moment from 'moment';
import RoutePath from '../../../RoutePath';
import {
  getTelematicAccountFormConfig, telematicAccountDetails,
} from '../../../store/selectors';
import {
  fetchTelematicAccountFormConfig, fetchTelematicAccountDetails,
  GET_TELEMATIC_ACCOUNT_DETAILS,
} from '../../../store/actions';
import { GoBackButton } from '../../../components/Styled/Custom';
import { FullSpace, Loader } from '../../../components';
import { DateTime } from '../../../components/Vehicle/Data';
import SupportButton from '../Components/SupportButton';
import DevicesContent from './Components/DevicesContent';
import { DATE_WITH_DOTS } from '../../../utils/constants/timeFormats';
import { DeviceContext } from '../../../types';

const DetailName = styled(Row)`
  opacity: 0.45;
  white-space: nowrap;
`;

const DefaultDetailValue = styled(Row)`
  font-size: 20px;
  font-weight: 500;
`;

const ScrollableDetailValue = styled(DefaultDetailValue)`
  white-space: nowrap;
  overflow-x: auto;
  margin-bottom: -.2em;
  ::-webkit-scrollbar {
    height: .2em;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 100vw;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 100vw;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const VehiclesCountType = {
  NEW: 'NEW',
  CONNECTED: 'CONNECTED',
  CONNECTION_ISSUE: 'CONNECTION_ISSUE',
  DEACTIVATED: 'DEACTIVATED',
};

const VehiclesCountZeroValue = styled(ScrollableDetailValue)`
  opacity: 0.25;
`;

const NewVehiclesCountValue = styled(ScrollableDetailValue)`
  color: rgba(24, 144, 255, 1);
`;

const ConnectedVehiclesCountValue = styled(ScrollableDetailValue)`
  color: rgba(82, 196, 26, 1);
`;

const ConnectionIssueVehiclesCountValue = styled(ScrollableDetailValue)`
  color: rgba(250, 140, 22, 1);
`;

const DeactivatedVehiclesCountValue = styled(ScrollableDetailValue)`
  color: rgba(0, 0, 0, 0.85);
`;

const vehiclesCountByType = (type, count) => {
  switch (type) {
    case VehiclesCountType.NEW:
      return <NewVehiclesCountValue>{count}</NewVehiclesCountValue>;
    case VehiclesCountType.CONNECTED:
      return <ConnectedVehiclesCountValue>{count}</ConnectedVehiclesCountValue>;
    case VehiclesCountType.CONNECTION_ISSUE:
      return <ConnectionIssueVehiclesCountValue>{count}</ConnectionIssueVehiclesCountValue>;
    case VehiclesCountType.DEACTIVATED:
      return <DeactivatedVehiclesCountValue>{count}</DeactivatedVehiclesCountValue>;
    default:
      return <VehiclesCountZeroValue>{count}</VehiclesCountZeroValue>;
  }
};

const vehiclesCount = (type, count) => (
  count ? vehiclesCountByType(type, count) : <VehiclesCountZeroValue>{count}</VehiclesCountZeroValue>
);

const oneHourInSeconds = 60 * 60;

const TelematicAccountDetailsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();

  const { accountId } = useParams();

  const [deviceContentContext, setDeviceContentContext] = useState(null);

  const {
    fetchStatus,
    accountDetails,
  } = useSelector(telematicAccountDetails);
  const { telematicAccountFormConfig } = useSelector(getTelematicAccountFormConfig);
  const [devicesForm] = Form.useForm();

  useEffect(() => {
    dispatch(fetchTelematicAccountDetails(accountId));
    dispatch(fetchTelematicAccountFormConfig());
  }, [accountId, dispatch]);

  useEffect(() => {
    if (!accountDetails || deviceContentContext) {
      return;
    }
    if (accountDetails.newVehiclesCount) {
      setDeviceContentContext(DeviceContext.NEW);
    } else if ((accountDetails.connectedVehiclesCount
      || accountDetails.connectionIssueVehiclesCount)) {
      setDeviceContentContext(DeviceContext.CONNECTED);
    } else {
      setDeviceContentContext(DeviceContext.NEW);
    }
  }, [accountDetails, deviceContentContext]);

  const loaderTextKey = `TELEMATICS.ACCOUNT.DETAILS.FETCH.${fetchStatus}`;
  const renderErrorMessage = () => (
    <FullSpace height="500px">
      <span style={{ fontSize: '20px' }}>{t(loaderTextKey)}</span>
    </FullSpace>
  );
  const renderLoader = () => <Loader text={t(loaderTextKey)} />;

  const renderContent = () => {
    const providerDisplayName = accountDetails.telematicProvider.displayName;
    const devicesTabsViewEnabled = !!(accountDetails.newVehiclesCount
      || accountDetails.connectedVehiclesCount
      || accountDetails.connectionIssueVehiclesCount
      || accountDetails.deactivatedVehiclesCount
      || moment().unix() - accountDetails.creationDate > oneHourInSeconds);
    const vins = telematicAccountFormConfig
      .find(({ providerId }) => providerId === accountDetails.telematicProvider.id)
      ?.fields
      .some(({ fieldName }) => fieldName === 'vins');
    const viewGpsConnectionDisabled = !!(vins);
    return (
      <>
        <BreadcrumbsItem to={RoutePath.telematicAccountsView}>{t('BREADCRUMB.TELEMATICS')}</BreadcrumbsItem>
        <BreadcrumbsItem to={RoutePath.telematicAccountDetails(accountId)}>
          {providerDisplayName}
        </BreadcrumbsItem>

        <Row style={{ marginTop: '10px' }}>
          <GoBackButton
            onClick={() => (history.push(RoutePath.telematicAccountsView))}
            text="TELEMATICS.ACCOUNT.DETAILS.BUTTON_BACK_TO_TELEMATICS_PAGE"
          />
        </Row>

        <Card bodyStyle={{ padding: '12px 24px' }} style={{ marginTop: '20px' }}>
          <Row justify="space-between">
            <Col span={6}>
              <DetailName>
                {t('TELEMATICS.ACCOUNT.DETAILS.TELEMATIC_PROVIDER')}
              </DetailName>
              {viewGpsConnectionDisabled ? (
                <ScrollableDetailValue>
                  {providerDisplayName}
                </ScrollableDetailValue>
              ) : (
                <ScrollableDetailValue style={{ fontWeight: '400' }}>
                  <Link to={RoutePath.telematicAccountDetailsView(accountId)}>
                    {providerDisplayName}
                  </Link>
                </ScrollableDetailValue>
              )}
            </Col>
            <Col span={3}>
              <DetailName>
                {t('TELEMATICS.ACCOUNT.DETAILS.CREATION_DATE')}
              </DetailName>
              <ScrollableDetailValue>
                <DateTime value={accountDetails.creationDate} dateTimeFormat={DATE_WITH_DOTS} />
              </ScrollableDetailValue>
            </Col>
            <Col span={3}>
              <DetailName>
                {t('TELEMATICS.ACCOUNT.DETAILS.NEW_VEHICLES')}
              </DetailName>
              {vehiclesCount(VehiclesCountType.NEW, accountDetails.newVehiclesCount)}
            </Col>
            <Col span={3}>
              <DetailName>
                {t('TELEMATICS.ACCOUNT.DETAILS.CONNECTED_VEHICLES')}
              </DetailName>
              {vehiclesCount(VehiclesCountType.CONNECTED, accountDetails.connectedVehiclesCount)}
            </Col>
            <Col span={3}>
              <DetailName>
                {t('TELEMATICS.ACCOUNT.DETAILS.CONNECTION_ISSUE_VEHICLES')}
              </DetailName>
              {vehiclesCount(VehiclesCountType.CONNECTION_ISSUE, accountDetails.connectionIssueVehiclesCount)}
            </Col>
            <Col span={3}>
              <DetailName>
                {t('TELEMATICS.ACCOUNT.DETAILS.DEACITVATED_VEHICLES')}
              </DetailName>
              {vehiclesCount(VehiclesCountType.DEACTIVATED, accountDetails.deactivatedVehiclesCount)}
            </Col>
          </Row>
        </Card>

        <DevicesContent
          accountId={accountId}
          viewGpsConnectionDisabled={viewGpsConnectionDisabled}
          devicesTabsViewEnabled={devicesTabsViewEnabled}
          accountDetails={accountDetails}
          deviceContext={deviceContentContext}
          setDeviceContext={setDeviceContentContext}
          devicesForm={devicesForm}
        />

        <Row style={{ margin: '20px 0px 56px' }}>
          <Col xs={12}>
            <SupportButton form={form} disabled />
          </Col>
        </Row>
      </>
    );
  };

  switch (fetchStatus) {
    case GET_TELEMATIC_ACCOUNT_DETAILS.START:
      return renderLoader();
    case GET_TELEMATIC_ACCOUNT_DETAILS.SUCCESS:
      return telematicAccountFormConfig.length > 0 && deviceContentContext && renderContent();
    case GET_TELEMATIC_ACCOUNT_DETAILS.FAIL:
    default:
      return renderErrorMessage();
  }
};

export default TelematicAccountDetailsPage;
