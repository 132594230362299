import axiosActionsFactory from '../../axiosHelpers';
import { UrlService } from '../../../../services';
import { getSelectedCompany } from '../../../selectors';

export const EDIT_TELEMATIC_ACCOUNT_DEVICES = axiosActionsFactory('EDIT_TELEMATIC_ACCOUNT_DEVICES');

const editTelematicAccountNewDevices = (
  params, request,
) => (dispatch) => dispatch({
  type: EDIT_TELEMATIC_ACCOUNT_DEVICES.START,
  payload: {
    request: {
      url: UrlService.editVehicles(),
      method: 'post',
      params,
      data: request,
    },
  },
});

const buildRequest = (values) => ({
  vehicles: Object.values(values.devices).map(({
    deviceId, vehicleId, licensePlateNumber, licensePlateCountryCode, vin, vehicleType,
  }) => ({
    deviceId,
    vehicleId,
    licensePlateNumber,
    licensePlateCountryCode,
    vin,
    type: vehicleType,
  })),
});

export const editVehicles = (values) => (dispatch, getState) => {
  const state = getState();
  const contextCompanyId = getSelectedCompany(state);
  const request = buildRequest(values);

  return dispatch(editTelematicAccountNewDevices(
    { contextCompanyId }, request,
  ));
};
