import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Alert, Button, Empty, Form, Modal, Row,
  Spin,
} from 'antd';
import { Trans } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { LanguageService as i18n } from '../../../../services';
import {
  CompressedSection,
} from '../../../../components';
import { DeviceContext } from '../../../../types';
import {
  DevicesColumnKey as ColumnKey,
} from './DevicesTable.constants';
import {
  devicesEditMode, devicesPrevActionName, getDevicesContent, getDevicesRows,
  getSubmittedDevicesRows,
} from '../../../../store/selectors';
import {
  ACTIVATE_TELEMATIC_ACCOUNT_DEVICES,
  activateDevices,
  addAllRowErrors, EDIT_TELEMATIC_ACCOUNT_DEVICES, editVehicles, exitConnectedDevicesEditMode,
} from '../../../../store/actions';
import DevicesTable from './DevicesTable';
import {
  ERROR_CODE, LICENSE_PLATE_COUNTRY_CODE_ERROR_CODES, LICENSE_PLATE_ERROR_CODES, licensePlateCountryCodeField,
  licensePlateNumberField, POST_FORM_SUBMIT_ERROR_CODES, VEHICLE_TYPE_ERROR_CODES, vehicleTypeField, VIN_ERROR_CODES,
  vinField,
} from './DevicesForm.constants';
import { setPrevAction } from '../../../../store/actions/telematicAccounts/devices/prevAction.actions';
import { ConnectionStatus, DEVICES_ACTION } from './DevicesContent.constants';

const DEVICES = 'TELEMATICS.ACCOUNT.DETAILS.DEVICES';

const defaultModalProps = {
  centered: true,
};

const DevicesFormTable = ({
  devices, onSetPagination, onSetSorting, filters, updateFilters, isTableView,
  viewGpsConnectionDisabled, deviceContext, changeTab, form, vins,
}) => {
  const dispatch = useDispatch();

  const { isEnabled: isEditMode } = useSelector(devicesEditMode(deviceContext));
  const rows = useSelector(isEditMode ? getDevicesRows(deviceContext) : getDevicesContent(deviceContext));
  const prevActionName = useSelector(devicesPrevActionName(deviceContext));
  const submittedDevicesRows = useSelector(getSubmittedDevicesRows(deviceContext));

  const selectedRows = rows?.filter((row) => row.isSelected);
  const selectedDeviceIds = selectedRows?.map((d) => d.deviceId);

  const [isSpinning, setIsSpinning] = useState(false);

  const countNeedVerificationDevices = (needVerificationDevices) => (
    needVerificationDevices.filter(
      (row) => row.connectionStatus === ConnectionStatus.NEEDS_VERIFICATION,
    ).length
  );

  useEffect(() => {
    if (prevActionName === DEVICES_ACTION.SUBMIT_CONNECTED_VEHICLES_FORM_SUCCESS && submittedDevicesRows.length > 0) {
      const needVerificationDevicesCount = countNeedVerificationDevices(submittedDevicesRows);
      if (needVerificationDevicesCount > 0) {
        Modal.warning({
          title: i18n.t(`${DEVICES}.MODAL.SUBMIT.REVIEW_NEEDED.TITLE`),
          content: i18n.t(`${DEVICES}.MODAL.SUBMIT.REVIEW_NEEDED.CONTENT`, { needVerificationDevicesCount }),
          okText: i18n.t('COMMON.OK'),
          ...defaultModalProps,
        });
      } else {
        Modal.success({
          title: i18n.t(`${DEVICES}.MODAL.SUBMIT.SUCCESS.TITLE`),
          content: i18n.t(`${DEVICES}.MODAL.SUBMIT.SUCCESS.CONTENT`),
          okText: i18n.t('COMMON.OK'),
          ...defaultModalProps,
        });
      }
    }
  }, [prevActionName, submittedDevicesRows]);

  useEffect(() => {
    if (prevActionName === DEVICES_ACTION.SUBMIT_NEW_VEHICLES_FORM_SUCCESS && submittedDevicesRows.length > 0) {
      const submittedDevicesRowsIds = submittedDevicesRows.map(({ deviceId }) => deviceId);
      if (!rows.some((row) => submittedDevicesRowsIds.includes(row.deviceId))) {
        changeTab(DeviceContext.CONNECTED);
      }
    }
  }, [prevActionName, submittedDevicesRows, rows, changeTab]);

  const vinsError = () => {
    if (vins?.details?.lastFailed?.length && vins?.details?.lastSubmitted?.length) {
      return (
        <Alert
          type="error"
          showIcon
          message={(
            <Trans>
              {i18n.t(`${DEVICES}.ALERT.ERROR.MISMATCHED_VINS`,
                { failedCount: vins.details.lastFailed.length, submittedCount: vins.details.lastSubmitted.length })}
            </Trans>
          )}
          style={{ marginBottom: '24px' }}
        />
      );
    }
    if (vins?.details?.lastDuplicated?.length) {
      return (
        <Alert
          type="error"
          showIcon
          message={(
            <Trans>
              {i18n.t(`${DEVICES}.ALERT.ERROR.DUPLICATED_VINS`,
                { duplicatedCount: vins.details.lastDuplicated.length })}
            </Trans>
          )}
          style={{ marginBottom: '24px' }}
        />
      );
    }
    return <></>;
  };

  const errorAlert = (errorCode) => {
    const vehiclesCount = selectedRows.filter((row) => row.errors.includes(errorCode))
      .map(({ vehicleId }) => vehicleId).filter((value, index, array) => array.indexOf(value) === index).length;
    return vehiclesCount > 0 ? (
      <Alert
        type="error"
        showIcon
        message={(
          <>
            <Trans>{i18n.t(`${DEVICES}.ALERT.ERROR.${errorCode}`, { vehiclesCount })}</Trans>
            {' '}
            {i18n.t(`${DEVICES}.ADDITIONAL.FIX_AND_RESUBMIT`)}
          </>
        )}
        style={{ marginBottom: '24px' }}
      />
    ) : <></>;
  };

  const warningAlert = (needVerificationDevices) => {
    const vehiclesCount = countNeedVerificationDevices(needVerificationDevices);
    return vehiclesCount > 0 ? (
      <Alert
        type="warning"
        showIcon
        message={(
          <Trans>
            {i18n.t(`${DEVICES}.ALERT.WARNING.VEHICLE_NEEDS_VERIFICATION`, { vehiclesCount })}
          </Trans>
        )}
        closable
        style={{ marginBottom: '24px' }}
      />
    ) : <></>;
  };

  const onCancelEditMode = () => {
    dispatch(exitConnectedDevicesEditMode());
    form.resetFields();
  };

  const rowFields = (deviceId) => [
    ColumnKey.deviceId, ColumnKey.vehicleId, ColumnKey.vin, ColumnKey.licensePlateCountryCode,
    ColumnKey.licensePlateNumber, ColumnKey.vehicleType,
  ].map((columnKey) => ['devices', deviceId, columnKey]);

  const activateNewDevices = () => {
    dispatch(activateDevices(selectedDeviceIds))
      .then(({ type }) => {
        if (type !== ACTIVATE_TELEMATIC_ACCOUNT_DEVICES.SUCCESS) {
          setIsSpinning(false);
          return;
        }
        setTimeout(() => {
          dispatch(setPrevAction(DEVICES_ACTION.SUBMIT_NEW_VEHICLES_FORM_SUCCESS, DeviceContext.NEW));
          setIsSpinning(false);
        }, 2000);
      });
  };

  const handleErrorVehicles = (errorVehicles) => {
    const errorRows = errorVehicles.map(({ deviceId, error }) => {
      let errorCode = error.code;
      const errorMessage = error.message;
      if (errorCode === 'VEHICLE_UPDATE_FAILED') {
        errorCode = errorMessage;
      }
      let fieldName = 0;
      if (VIN_ERROR_CODES.includes(errorCode)) {
        fieldName = vinField(deviceId);
      } else if (LICENSE_PLATE_ERROR_CODES.includes(errorCode)) {
        fieldName = licensePlateNumberField(deviceId);
      } else if (LICENSE_PLATE_COUNTRY_CODE_ERROR_CODES.includes(errorCode)) {
        fieldName = licensePlateCountryCodeField(deviceId);
      } else if (VEHICLE_TYPE_ERROR_CODES.includes(errorCode)) {
        fieldName = vehicleTypeField(deviceId);
      }
      const rowError = { deviceId, errors: [errorCode] };
      const field = { name: fieldName, errors: [errorMessage] };
      if (errorCode === ERROR_CODE.VEHICLE_LICENSE_PLATE_DUPLICATE) {
        field.value = null;
      }
      return { rowError, field };
    });

    form.setFields(errorRows.map(({ field }) => field));
    dispatch(addAllRowErrors(deviceContext, errorRows.map(({ rowError }) => rowError)));
  };

  const update = (values) => {
    setIsSpinning(true);
    dispatch(editVehicles(values))
      .then(({ payload, type }) => {
        if (type !== EDIT_TELEMATIC_ACCOUNT_DEVICES.SUCCESS) {
          return;
        }
        const errorVehicles = payload.data.vehicles.filter(({ error }) => error !== undefined && error !== null);
        switch (deviceContext) {
          case DeviceContext.NEW:
            if (errorVehicles.length) {
              dispatch(setPrevAction(DEVICES_ACTION.SUBMIT_NEW_VEHICLES_FORM_ERROR, deviceContext));
              handleErrorVehicles(errorVehicles);
              setIsSpinning(false);
            } else {
              activateNewDevices();
            }
            break;
          case DeviceContext.CONNECTED:
            if (errorVehicles.length) {
              dispatch(setPrevAction(DEVICES_ACTION.SUBMIT_CONNECTED_VEHICLES_FORM_ERROR, deviceContext));
              handleErrorVehicles(errorVehicles);
              Modal.error({
                title: i18n.t(`${DEVICES}.MODAL.SUBMIT.ERROR.TITLE`),
                content: (
                  <>
                    {i18n.t(`${DEVICES}.ADDITIONAL.FIX_AND_RESUBMIT`)}
                    {':'}
                    <ul>
                      {POST_FORM_SUBMIT_ERROR_CODES.map((error) => {
                        const vehiclesCount = selectedRows.filter((row) => row.errors.includes(error)).length;
                        if (vehiclesCount > 0) {
                          return <li><Trans>{i18n.t(`${DEVICES}.ALERT.ERROR.${error}`, { vehiclesCount })}</Trans></li>;
                        }
                        return <></>;
                      })}
                    </ul>
                  </>
                ),
                okText: i18n.t('COMMON.GO_BACK'),
                ...defaultModalProps,
              });
              setIsSpinning(false);
            } else {
              setTimeout(() => {
                dispatch(setPrevAction(DEVICES_ACTION.SUBMIT_CONNECTED_VEHICLES_FORM_SUCCESS, deviceContext));
                dispatch(exitConnectedDevicesEditMode());
                setIsSpinning(false);
              }, 2000);
            }
            break;
          default:
            setIsSpinning(false);
        }
      });
  };

  const handleSubmit = () => {
    form.validateFields(selectedDeviceIds.flatMap((deviceId) => rowFields(deviceId))).then((values) => {
      switch (deviceContext) {
        case DeviceContext.NEW: {
          Modal.confirm({
            icon: <ExclamationCircleOutlined style={{ color: '#1890FF' }} />,
            title: i18n.t(`${DEVICES}.MODAL.SUBMIT.CONFIRM.TITLE`),
            content: <Trans i18nKey={`${DEVICES}.MODAL.SUBMIT.CONFIRM.CONTENT`} />,
            okText: i18n.t('COMMON.CONFIRM'),
            onOk: () => update(values),
            cancelText: i18n.t('COMMON.GO_BACK'),
            ...defaultModalProps,
          });
          break;
        }
        case DeviceContext.CONNECTED: {
          update(values);
          break;
        }
        default:
      }
    });
  };

  const renderWarningAlerts = (
    <>
      {warningAlert(rows)}
    </>
  );

  const renderDevicesTable = (
    <DevicesTable
      devices={devices}
      onSetPagination={onSetPagination}
      onSetSorting={onSetSorting}
      filters={filters}
      updateFilters={updateFilters}
      isTableView={isTableView}
      viewGpsConnectionDisabled={viewGpsConnectionDisabled}
      deviceContext={deviceContext}
      form={form}
      vins={vins}
      rows={rows}
    />
  );

  const renderTableOrElseEmpty = (
    isTableView ? (
      <>
        {isEditMode ? (
          <>
            {vinsError()}
            <Alert
              type="info"
              showIcon
              message={<Trans i18nKey={`${DEVICES}.ALERT.INFO.CONFIRM_CORRECT_VEHICLES`} />}
              closable
              style={{ marginBottom: '24px' }}
            />
            {errorAlert(ERROR_CODE.MISSING_LICENSE_PLATES)}
            {errorAlert(ERROR_CODE.INVALID_LICENSE_PLATE)}
            {errorAlert(ERROR_CODE.LICENSE_PLATE_ALREADY_EXISTS)}
            {errorAlert(ERROR_CODE.VEHICLE_LICENSE_PLATE_DUPLICATE)}
            {errorAlert(ERROR_CODE.INVALID_VIN)}
            {errorAlert(ERROR_CODE.VIN_ALREADY_EXISTS)}
            {errorAlert(ERROR_CODE.MULTIPLE_LICENSE_PLATE_NUMBER_CHANGES)}
            {errorAlert(ERROR_CODE.MULTIPLE_LICENSE_PLATE_COUNTRY_CODE_CHANGES)}
            {errorAlert(ERROR_CODE.MULTIPLE_VIN_CHANGES)}
            {errorAlert(ERROR_CODE.MULTIPLE_VEHICLE_TYPE_CHANGES)}
          </>
        ) : <></>}
        {form ? (
          <Form
            form={form}
          >
            <CompressedSection>
              <Form.List name="devices">
                {() => renderDevicesTable}
              </Form.List>
            </CompressedSection>
          </Form>
        ) : renderDevicesTable}
        {isEditMode ? (
          <Row justify="end">
            <Button
              style={{ marginRight: '8px' }}
              onClick={() => onCancelEditMode()}
              disabled={deviceContext === DeviceContext.NEW}
            >
              {i18n.t('COMMON.CANCEL')}
            </Button>
            <Button
              type="primary"
              onClick={handleSubmit}
              disabled={!selectedRows.length}
              style={!selectedRows.length || selectedRows.some((row) => row.errors.length > 0)
                ? { background: '#BDBDBD', borderColor: '#BDBDBD' }
                : { background: '#1890ff', borderColor: '#1890ff' }}
            >
              {i18n.t('COMMON.SUBMIT')}
            </Button>
          </Row>
        ) : <></>}
      </>
    ) : (
      <>
        <Empty description={i18n.t(`${DEVICES}.FETCH.GET_DEVICES_NOT_FOUND`)} />
      </>
    )
  );

  return (
    <>
      <Spin spinning={isSpinning}>
        {renderWarningAlerts}
        {renderTableOrElseEmpty}
      </Spin>
    </>
  );
};

DevicesFormTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  devices: PropTypes.any.isRequired,
  onSetPagination: PropTypes.func.isRequired,
  onSetSorting: PropTypes.func.isRequired,
  filters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.any)),
  updateFilters: PropTypes.func.isRequired,
  viewGpsConnectionDisabled: PropTypes.bool.isRequired,
  isTableView: PropTypes.bool,
  deviceContext: PropTypes.oneOf(Object.values(DeviceContext)).isRequired,
  changeTab: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  vins: PropTypes.any,
};

DevicesFormTable.defaultProps = {
  filters: {},
  isTableView: false,
  changeTab: () => { },
  form: undefined,
  vins: null,
};

export default DevicesFormTable;
