import { DeviceContext } from '../../../../types';
import { uuidv4 } from '../../../../utils/uuid';

const SET_NEW_DEVICES_PREV_ACTION = 'SET_NEW_DEVICES_PREV_ACTION';
const SET_CONNECTED_DEVICES_PREV_ACTION = 'SET_CONNECTED_DEVICES_PREV_ACTION';
const SET_DEACTIVATED_DEVICES_PREV_ACTION = 'SET_DEACTIVATED_DEVICES_PREV_ACTION';

export const SET_DEVICES_PREV_ACTION = {
  [DeviceContext.NEW]: SET_NEW_DEVICES_PREV_ACTION,
  [DeviceContext.CONNECTED]: SET_CONNECTED_DEVICES_PREV_ACTION,
  [DeviceContext.DEACTIVATED]: SET_DEACTIVATED_DEVICES_PREV_ACTION,
};

export const setPrevAction = (name, deviceContext = DeviceContext.CONNECTED) => ({
  type: SET_DEVICES_PREV_ACTION[deviceContext],
  payload: {
    prevAction: {
      id: uuidv4(),
      name,
    },
  },
});
