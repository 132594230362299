import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Row,
  Tag,
} from 'antd';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined, WarningOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import {
  analysedTracePropTypes, etaPropTypes, orderPositionsPropTypes, orderPropTypes, OrderContext, geofencingDataPropTypes,
} from '../../../types';
import DetailsDescriptionsCard from '../../../components/Styled/Custom/DetailsDescriptionsCard';
import { DateTime } from '../../../components/Vehicle/Data';
import CompanyInfo from '../../../components/Vehicle/Data/CompanyInfo';
import OrderStatus from '../Components/OrderStatusComponent';
import { isFeatureEnabled } from '../../../store/selectors';
import { FeatureToggle } from '../../../store/actions';
import { GetLocation, LatLng } from '../../../components';
import TourDetailsCard from './TourDetailsCard';
import BreaksDescriptionsCard from './BreaksDescriptionsCard';
import { shouldShowTimeframes } from '../../../services/OrdersService';
import Elapsed from '../../../components/Vehicle/Data/Elapsed';

const OrderDetailsPane = ({
  order, loading, cardSize, detailsSize, side, vehiclePositions, selectedVehicle, geofencingWithEtaEnabled,
  eta, geofencingData, renderBreaks, onRenderBreaksChange, analysis,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const tourStatisticsEnabled = useSelector(isFeatureEnabled(FeatureToggle.TOUR_STATISTICS));

  const goToStatistics = () => {
    history.push(`/orders/statistics/${side}/${order.shipperCompany.id}/${order.internalId}`);
  };

  const getLastPosition = (positions, vehicleId) => {
    if (!(positions && positions[vehicleId] && positions[vehicleId].positions
      && positions[vehicleId].positions.length)) {
      return {};
    }
    const thesePositions = positions[vehicleId].positions;
    return thesePositions[thesePositions.length - 1];
  };

  const details = {
    order: [{
      title: 'ORDER.TRANSPORT_ORDER_NUMBER',
      renderDetail: () => ((order && order.internalId) || t('COMMON.MISSING')),
      loading: () => loading,
    }, {
      title: 'PROPERTIES.STATUS',
      renderDetail: () => (order && <OrderStatus status={order.status} />),
      loading: () => loading,
    }, {
      title: 'PROPERTIES.SHIPPER',
      loading: () => loading,
      renderDetail: () => (
        (order && order.shipperCompany && <CompanyInfo company={order.shipperCompany} />) || t('COMMON.MISSING')
      ),
    }, {
      title: 'PROPERTIES.CARRIER',
      loading: () => loading,
      renderDetail: () => (
        (order && order.carrierCompany && <CompanyInfo company={order.carrierCompany} />) || t('COMMON.MISSING')
      ),
    }, {
      title: 'ORDER.START_DATE',
      renderDetail: () => (
        <DateTime
          elapsed
          value={order && order.startDate}
        />
      ),
      loading: () => loading,
    }, {
      title: 'ORDER.END_DATE',
      renderDetail: () => (
        <DateTime
          value={order && order.endDate}
          elapsed
        />
      ),
      loading: () => loading,
    }, {
      title: 'ORDER.CREATED_DATE',
      renderDetail: () => (
        <DateTime
          value={order && order.creationDate}
          elapsed
        />
      ),
      loading: () => loading,
    }, {
      title: 'ORDER.STATUS_DATE',
      renderDetail: () => (
        <DateTime
          value={order && order.statusDate}
          elapsed
        />
      ),
      loading: () => loading,
    }],
    vehicles: ((order && order.vehicles) || []).map(({ id, licencePlateNumber, trackingTimeframe }) => {
      const result = [{
        title: 'PROPERTIES.VEHICLE_PLATE_NO',
        renderDetail: () => (
          order && side === OrderContext.IN
            ? <Link to={`/vehicles/${id}`}>{licencePlateNumber}</Link> : licencePlateNumber
        ) || t('COMMON.MISSING'),
        loading: () => loading,
      }, {
        title: 'PROPERTIES.LAST_UPDATE',
        renderDetail: () => {
          const lastPosition = getLastPosition(vehiclePositions, id);
          return (
            <>
              <DateTime
                value={lastPosition.positionTimestamp}
                elapsed
                noBrackets
              />
              {lastPosition.serverTimestamp && (
                <span style={{ fontSize: '10px', color: 'gray' }}>
                  <Elapsed value={lastPosition.serverTimestamp} prefix={`${t('PROPERTIES.LAST_CHECK')} `} />
                </span>
              )}
            </>
          );
        },
        loading: () => loading,
      }, {
        title: 'PROPERTIES.COORDINATES',
        renderDetail: () => (
          <LatLng
            latitude={getLastPosition(vehiclePositions, id).coordinateLatitude}
            longitude={getLastPosition(vehiclePositions, id).coordinateLongitude}
            copy
            hideEmpty
          />
        ),
        loading: () => loading,
      }, {
        title: 'PROPERTIES.LAST_LOCATION',
        renderDetail: () => (
          <GetLocation
            hideRefresh
            fetchOnStart
            vehicleId={`${order.id}-${id}`}
            coordinates={{
              lng: getLastPosition(vehiclePositions, id).coordinateLongitude,
              lat: getLastPosition(vehiclePositions, id).coordinateLatitude,
            }}
          />
        ),
        loading: () => loading,
      }];

      if (shouldShowTimeframes(order.startDate, order.endDate, trackingTimeframe.periods)) {
        const toleranceBefore = order.trackingToleranceWindowInMinutes.before;
        const toleranceAfter = order.trackingToleranceWindowInMinutes.after;
        const now = moment();
        result.push({
          title: 'PROPERTIES.TIMEFRAMES',
          renderDetail: () => (
            trackingTimeframe?.periods?.sort((a, b) => moment(a.start).unix() - moment(b.start).unix())
              .map((period, i) => (
                <div
                  style={{ marginTop: i === 0 ? 0 : '10px' }}
                  key={period.start}
                >
                  {moment.utc(period.start).add(-toleranceBefore, 'minute').isAfter(now)
                      && (
                        <div>
                          <Tag color="orange" icon={<WarningOutlined />}>
                            {t('ORDER.TIMEFRAME_NOT_STARTED')}
                          </Tag>
                        </div>
                      )}
                  {moment.utc(period.start).add(-toleranceBefore, 'minute').isBefore(now)
                      && moment.utc(period.start).isAfter(now)
                      && (
                        <div>
                          <Tag color="orange" icon={<WarningOutlined />}>
                            {t('ORDER.TIMEFRAME_STARTS_SOON')}
                          </Tag>
                        </div>
                      )}
                  {moment.utc(period.end).add(toleranceAfter, 'minute').isBefore(now) && (
                    <div>
                      <Tag color="orange" icon={<WarningOutlined />}>
                        {t('ORDER.TIMEFRAME_ENDED')}
                      </Tag>
                    </div>
                  )}
                  {moment.utc(period.end).isBefore(now)
                    && moment.utc(period.end).add(toleranceAfter, 'minute').isAfter(now)
                    && (
                      <div>
                        <Tag color="orange" icon={<WarningOutlined />}>
                          {t('ORDER.TIMEFRAME_ENDS_SOON')}
                        </Tag>
                      </div>
                    )}
                  {moment.utc(period.start).isBefore(now) && moment.utc(period.end).isAfter(now) && (
                    <div>
                      <Tag color="green">
                        {t('ORDER.TIMEFRAME_ACTIVE')}
                      </Tag>
                    </div>
                  )}
                  <div>
                    {t('PROPERTIES.FROM_WITH_COLON')}
                    <div style={{ display: 'inline-block', marginLeft: '5px' }}>
                      <DateTime value={period.start} />
                    </div>
                  </div>
                  <div>
                    {t('PROPERTIES.TO_WITH_COLON')}
                    <div style={{ display: 'inline-block', marginLeft: '5px' }}>
                      <DateTime value={period.end} />
                    </div>
                  </div>
                </div>
              ))
          ),
          loading: () => loading,
        });
      }

      return { name: licencePlateNumber, data: result };
    }),
  };

  function hasVehiclePositions() {
    const selectedVehiclePositions = vehiclePositions[selectedVehicle];
    return !!(selectedVehiclePositions
      && selectedVehiclePositions.positions
      && selectedVehiclePositions.positions.length);
  }

  return (
    <Row gutter={[10, 10]} style={{ marginBottom: '56px' }}>
      <Col xs={24}>
        <TourDetailsCard
          geofencingWithEtaEnabled={geofencingWithEtaEnabled}
          eta={eta}
          geofencingData={geofencingData}
          stops={order && order.stops}
          orderId={order.id}
          selectedVehicle={selectedVehicle}
          shipperCompanyId={order.shipperCompany && order.shipperCompany.id}
          hasPosition={hasVehiclePositions()}
          orderStatus={order && order.status}
        />
      </Col>
      <Col xs={24}>
        <DetailsDescriptionsCard
          cardSize={cardSize}
          title={t('PROPERTIES.ORDER')}
          detailsSize={detailsSize}
          detailsConfig={details.order}
          actions={tourStatisticsEnabled ? [
            (
              <Button
                type="link"
                key="open-statistics"
                disabled={loading || !hasVehiclePositions()}
                icon={<ArrowRightOutlined />}
                onClick={goToStatistics}
              >
                {' '}
                {t('ORDER.OPEN_STATISTICS')}
              </Button>
            ),
          ] : null}
        />
      </Col>
      <Col xs={24}>
        <BreaksDescriptionsCard
          breaks={analysis.data && analysis.data.events}
          stops={order && order.stops}
          loading={analysis.loading}
          renderBreaks={renderBreaks}
          onRenderBreaksChange={onRenderBreaksChange}
        />
      </Col>

      {details.vehicles.length === 1 && (
        <Col xs={24}>
          <DetailsDescriptionsCard
            cardSize={cardSize}
            title={t('PROPERTIES.VEHICLE')}
            detailsSize={detailsSize}
            detailsConfig={details.vehicles[0].data}
          />
        </Col>
      )}

      {details.vehicles.length > 1 && details.vehicles.map(({ name, data }) => (
        <Col xs={24} key={name}>
          <DetailsDescriptionsCard
            cardSize={cardSize}
            title={`${t('PROPERTIES.VEHICLE')} ${name}`}
            detailsSize={detailsSize}
            detailsConfig={data}
          />
        </Col>
      ))}
    </Row>
  );
};

OrderDetailsPane.propTypes = {
  order: orderPropTypes,
  side: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  cardSize: PropTypes.oneOf(['small', 'medium', 'large']),
  detailsSize: PropTypes.oneOf(['small', 'medium', 'large']),
  selectedVehicle: PropTypes.string,
  vehiclePositions: PropTypes.objectOf(orderPositionsPropTypes),
  geofencingWithEtaEnabled: PropTypes.bool.isRequired,
  eta: etaPropTypes.isRequired,
  analysis: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    data: analysedTracePropTypes,
  }).isRequired,
  renderBreaks: PropTypes.bool.isRequired,
  onRenderBreaksChange: PropTypes.func.isRequired,
  geofencingData: geofencingDataPropTypes,
};

OrderDetailsPane.defaultProps = {
  cardSize: 'medium',
  detailsSize: 'small',
  order: {},
  vehiclePositions: {},
  selectedVehicle: null,
  geofencingData: {},
};

export default OrderDetailsPane;
