import axiosActionsFactory from '../axiosHelpers';
import { UrlService } from '../../../services';

export const GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY = axiosActionsFactory('GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY');

export const fetchTelematicAccountListForCompany = (contextCompanyId) => (dispatch) => dispatch({
  type: GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY.START,
  payload: {
    request: {
      url: UrlService.providerAccountsOverview(),
      method: 'get',
      params: { contextCompanyId },
    },
  },
});
