import axiosActionsFactory from '../../axiosHelpers';
import { UrlService } from '../../../../services';
import { getSelectedCompany } from '../../../selectors';

export const GET_TELEMATIC_ACCOUNT_DETAILS = axiosActionsFactory('GET_TELEMATIC_ACCOUNT_DETAILS');
export const REFRESH_TELEMATIC_ACCOUNT_DETAILS = axiosActionsFactory('REFRESH_TELEMATIC_ACCOUNT_DETAILS');

const getTelematicAccountDetails = (accountId, contextCompanyId, deviceContext, refresh) => (dispatch) => dispatch({
  type: refresh ? REFRESH_TELEMATIC_ACCOUNT_DETAILS.START : GET_TELEMATIC_ACCOUNT_DETAILS.START,
  payload: {
    request: {
      url: UrlService.providerAccountDetails(accountId),
      method: 'get',
      params: { contextCompanyId, deviceContext },
    },
  },
});

export const fetchTelematicAccountDetails = (accountId, deviceContexts, refresh = false) => (dispatch, getState) => {
  const state = getState();
  const contextCompanyId = getSelectedCompany(state);
  dispatch(getTelematicAccountDetails(accountId, contextCompanyId, deviceContexts?.join(','), refresh));
};
