/* eslint-disable max-len */
const telematicAccounts = '/telematic-accounts';

export default {
  telematicAccountsView: telematicAccounts,
  telematicAccountsCreate: `${telematicAccounts}/create`,
  telematicAccountDetails: (accountId) => `${telematicAccounts}/${accountId || ':accountId'}/details`,
  telematicAccountDetailsView: (accountId) => `${telematicAccounts}/${accountId || ':accountId'}/details/view`,
  telematicAccountDetailsAddVehicles: (accountId) => `${telematicAccounts}/${accountId || ':accountId'}/details/vehicles`,
};
