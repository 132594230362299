import axiosActionsFactory, { extractApiFilters } from '../../axiosHelpers';
import { UrlService } from '../../../../services';
import {
  devicesEditMode,
  devicesPrevActionName, getDevicesContextPagination, getDevicesContextSorting, getDevicesFilters, getSelectedCompany,
  getSelectedDeviceRowIds, tableNames,
} from '../../../selectors';
import { FILTERS } from '../../../../modules/Telematics/TelematicAccountDetails/Components/DevicesTable.constants';
import { DeviceContext } from '../../../../types';
import { setDevicesEditMode, updateDevicesEditMode } from './editMode.actions';
import {
  DEVICES_ACTION,
} from '../../../../modules/Telematics/TelematicAccountDetails/Components/DevicesContent.constants';

const GET_NEW_DEVICES = axiosActionsFactory('GET_NEW_DEVICES');
const GET_CONNECTED_DEVICES = axiosActionsFactory('GET_CONNECTED_DEVICES');
const GET_DEACTIVATED_DEVICES = axiosActionsFactory('GET_DEACTIVATED_DEVICES');

const REFRESH_NEW_DEVICES = axiosActionsFactory('REFRESH_NEW_DEVICES');
const REFRESH_CONNECTED_DEVICES = axiosActionsFactory('REFRESH_CONNECTED_DEVICES');
const REFRESH_DEACTIVATED_DEVICES = axiosActionsFactory('REFRESH_DEACTIVATED_DEVICES');

export const GET_DEVICES = {
  [DeviceContext.NEW]: GET_NEW_DEVICES,
  [DeviceContext.CONNECTED]: GET_CONNECTED_DEVICES,
  [DeviceContext.DEACTIVATED]: GET_DEACTIVATED_DEVICES,
};

export const REFRESH_DEVICES = {
  [DeviceContext.NEW]: REFRESH_NEW_DEVICES,
  [DeviceContext.CONNECTED]: REFRESH_CONNECTED_DEVICES,
  [DeviceContext.DEACTIVATED]: REFRESH_DEACTIVATED_DEVICES,
};

const getTelematicAccountNewDevices = (
  accountId, params, request, refresh, submittedDeviceRowIds = [],
) => (dispatch) => dispatch({
  type: refresh ? REFRESH_NEW_DEVICES.START : GET_NEW_DEVICES.START,
  payload: {
    submittedDeviceRowIds,
    request: {
      url: UrlService.providerAccountNewDevices(accountId),
      method: 'post',
      params,
      data: request || {
        pagination: {
          page: 0,
          size: 1,
        },
      },
    },
  },
});

const getTelematicAccountConnectedDevices = (
  accountId, params, request, refresh, submittedDeviceRowIds = [],
) => (dispatch) => dispatch({
  type: refresh ? REFRESH_CONNECTED_DEVICES.START : GET_CONNECTED_DEVICES.START,
  payload: {
    submittedDeviceRowIds,
    request: {
      url: UrlService.providerAccountConnectedDevices(accountId),
      method: 'post',
      params,
      data: request || {
        pagination: {
          page: 0,
          size: 1,
        },
      },
    },
  },
});

const getTelematicAccountDeactivatedDevices = (
  accountId, params, request, refresh,
) => (dispatch) => dispatch({
  type: refresh ? REFRESH_DEACTIVATED_DEVICES.START : GET_DEACTIVATED_DEVICES.START,
  payload: {
    request: {
      url: UrlService.providerAccountDeactivatedDevices(accountId),
      method: 'post',
      params,
      data: request || {
        pagination: {
          page: 0,
          size: 1,
        },
      },
    },
  },
});

const buildRequest = ({
  pagination, sorting, filters, deviceIds,
}) => {
  const request = {
    pagination: {
      page: pagination.page - 1,
      size: pagination.size,
    },
  };
  if (sorting && sorting.order && sorting.field) {
    request.pagination.sortProperty = sorting.field;
    request.pagination.sortDirection = sorting.order === 'ascend' ? 'ASC' : 'DESC';
  }
  const apiFilters = extractApiFilters(filters, FILTERS, () => true);
  request.filter = { ...apiFilters, deviceIds };
  return request;
};

export const fetchDevices = (
  accountId, deviceContext, refresh = false, deviceIds,
) => (dispatch, getState) => {
  const state = getState();
  const contextCompanyId = getSelectedCompany(state);
  const pagination = getDevicesContextPagination(state, deviceContext);
  const sorting = getDevicesContextSorting(state, deviceContext);
  const filters = getDevicesFilters(tableNames[deviceContext])(state);
  const request = buildRequest({
    pagination, sorting, filters, deviceIds,
  });

  const prevActionName = devicesPrevActionName(deviceContext)(state);
  switch (deviceContext) {
    case DeviceContext.NEW: {
      dispatch(getTelematicAccountNewDevices(
        accountId, { contextCompanyId }, request, refresh,
        prevActionName === DEVICES_ACTION.SUBMIT_NEW_VEHICLES_FORM_SUCCESS
          ? getSelectedDeviceRowIds(deviceContext)(state) : [],
      ))
        .then(() => {
          dispatch(!prevActionName || prevActionName === DEVICES_ACTION.TAB_CHANGE
            ? setDevicesEditMode(deviceContext) : updateDevicesEditMode(deviceContext));
        });
      break;
    }
    case DeviceContext.CONNECTED: {
      dispatch(getTelematicAccountConnectedDevices(accountId, { contextCompanyId }, request, refresh,
        DEVICES_ACTION.SUBMIT_CONNECTED_VEHICLES_FORM.includes(prevActionName)
          ? getSelectedDeviceRowIds(deviceContext)(state) : []))
        .then(() => {
          const { isEnabled: isEditMode } = devicesEditMode(deviceContext)(state);
          if (isEditMode) {
            dispatch(updateDevicesEditMode(deviceContext));
          }
        });
      break;
    }
    case DeviceContext.DEACTIVATED: {
      dispatch(getTelematicAccountDeactivatedDevices(accountId, { contextCompanyId }, request, refresh));
      break;
    }
    default:
  }
};
