import { DeviceContext } from '../../../../../types';
import { GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY } from '../../../../actions';
import { SET_DEVICES_SORTING } from '../../../../actions/telematicAccounts/devices/sorting.actions';

const INIT_SORTING = {
  order: null,
  field: null,
};

export default (deviceContext = DeviceContext.CONNECTED) => (state = { ...INIT_SORTING }, { type, payload }) => {
  switch (type) {
    case SET_DEVICES_SORTING[deviceContext]:
      return { ...payload };
    case GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY.SUCCESS:
      return INIT_SORTING;
    default:
      return state;
  }
};
