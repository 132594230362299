export const getTelematicAccountFormConfig = (state) => state.telematicAccounts.accountFormConfig;
export const getTelematicAccountListForCompany = (state) => state.telematicAccounts.telematicAccountListForCompany;
export const telematicAccountForEdit = (state) => state.telematicAccounts.telematicAccountForEdit;
export const telematicAccountDetails = (state) => state.telematicAccounts.telematicAccount.details;
export const telematicAccountDevices = (state) => state.telematicAccounts.telematicAccount.devices;
const getDevicesContextRoot = (state, deviceContext) => telematicAccountDevices(state)[deviceContext];
export const getDevices = (deviceContext) => (state) => getDevicesContextRoot(state, deviceContext);
export const getDevicesContent = (deviceContext) => (
  state,
) => getDevicesContextRoot(state, deviceContext).data.page.content;

export const devicesEditMode = (deviceContext) => (
  state,
) => getDevicesContextRoot(state, deviceContext).editMode;
export const devicesEditModeIsEnabled = (deviceContext) => (
  state,
) => getDevicesContextRoot(state, deviceContext).editMode.isEnabled;
export const getDevicesRows = (deviceContext) => (
  state,
) => getDevicesContextRoot(state, deviceContext).editMode.rows;
export const getSelectedDeviceRowIds = (deviceContext) => (
  state,
) => getDevicesContextRoot(state, deviceContext).editMode.rows?.filter((row) => row.isSelected)?.map((d) => d.deviceId);
export const getSubmittedDevicesRows = (deviceContext) => (
  state,
) => getDevicesContextRoot(state, deviceContext).editMode.submittedRows;

export const devicesPrevAction = (deviceContext) => (
  state,
) => getDevicesContextRoot(state, deviceContext).prevAction;
export const devicesPrevActionName = (deviceContext) => (
  state,
) => getDevicesContextRoot(state, deviceContext).prevAction?.name;

export const getDevicesContextPagination = (
  state, deviceContext,
) => getDevicesContextRoot(state, deviceContext).pagination;
export const getDevicesContextSorting = (state, deviceContext) => getDevicesContextRoot(state, deviceContext).sorting;
