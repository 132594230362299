import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import RoutePath from '../../../RoutePath';
import TelematicAccountForm from '../Components/TelematicAccountForm';
import {
  getSelectedCompany,
  getTelematicAccountFormConfig,
  telematicAccountForEdit,
} from '../../../store/selectors';
import { GET_TELEMATIC_ACCOUNT, getTelematicAccount, updateTelematicAccount } from '../../../store/actions';
import { FullSpace, Loader } from '../../../components';
import { TelematicAccountOperationType } from '../../../types/enums/telematicAccountFormViewType.enum';
import { getErrorCode, getPayloadData, isSuccess } from '../../../utils/responseUtils';
import { normalizeTelematicAccount } from '../../../store/normalizers';
import { ERROR_CODE } from '../../../utils/apiError';
import { uuidv4 } from '../../../utils/uuid';
import { submittedVinsSuccessModal } from '../TelematicAccountCreation/TelematicAccountCreationPage';

function mapAccountToForm(account) {
  return {
    accountDisplayName: account.accountDisplayName,
    accountDescription: account.accountDescription,
    selectedProvider: account.providerId,
    notFromListProviderCheckbox: false,
    ...(Object.fromEntries(Object.entries(account.credentials).map(([k, v]) => [k, v.value ?? v]))),
  };
}

export default function TelematicAccountEditPage({ operationType }) {
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  const [accountEditForm] = Form.useForm();

  const contextCompanyId = useSelector(getSelectedCompany);
  const { fetchStatus, account } = useSelector(telematicAccountForEdit);
  const { telematicAccountFormConfig } = useSelector(getTelematicAccountFormConfig);

  const [initialValues, setInitialValues] = useState();
  const [accountValidated, setAccountValidated] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const isAddVehiclesOperation = operationType === TelematicAccountOperationType.ADD_VEHICLES;
  const companyNotMathed = account?.companyId !== contextCompanyId;
  const hasVins = account?.credentials.vins !== undefined;
  const isWrongUpdateOperation = hasVins && operationType === TelematicAccountOperationType.UPDATE;
  const isWrongAddVehiclesOperation = !hasVins && isAddVehiclesOperation;

  useEffect(() => {
    dispatch(getTelematicAccount(accountId, contextCompanyId));
  }, [accountId, contextCompanyId, dispatch]);

  useEffect(() => {
    if (fetchStatus !== GET_TELEMATIC_ACCOUNT.SUCCESS || accountValidated) {
      return;
    }
    if (companyNotMathed) {
      history.push(RoutePath.telematicAccountsView);
      return;
    }
    if (isWrongUpdateOperation) {
      history.push(RoutePath.telematicAccountDetailsAddVehicles(account.accountId));
      return;
    }
    if (isWrongAddVehiclesOperation) {
      history.push(RoutePath.telematicAccountDetailsView(account.accountId));
      return;
    }
    setAccountValidated(true);
  }, [fetchStatus, accountValidated, companyNotMathed, isWrongUpdateOperation, isWrongAddVehiclesOperation, account,
    history]);

  const resolveEditedCredentialsSuccessModal = ({
    payloadData, title, content, goToDetailsPage,
  }) => (
    isAddVehiclesOperation
      ? (submittedVinsSuccessModal({
        payloadData, telematicAccountFormConfig, title, content, goToDetailsPage, isAddVehiclesOperation,
      }))
      : ({
        title: title.concat('.CREDENTIALS_UPDATED'),
        content: t(content.concat('.CREDENTIALS_UPDATED')),
        constructor: Modal.success,
        onOk: goToDetailsPage,
        okText: 'TELEMATICS.ACCOUNT.BUTTON_GO_TO_DETAILS_PAGE',
      })
  );

  const confirmationModalKeysAndConstructor = (response, resetForm, editedFields, handleUserNeedsSupport) => {
    const success = isSuccess(response);
    const statusSuffix = success ? 'SUCCESS' : 'ERROR';
    const title = `TELEMATICS.ACCOUNT.UPDATE.CONFIRMATION.TITLE.${statusSuffix}`;
    const content = `TELEMATICS.ACCOUNT.UPDATE.CONFIRMATION.CONTENT.${statusSuffix}`;

    if (success) {
      const payloadData = getPayloadData(response);
      setInitialValues(mapAccountToForm(normalizeTelematicAccount(payloadData.providerAccount)));
      resetForm();
      const goToDetailsPage = () => (
        history.push(RoutePath.telematicAccountDetails(payloadData.providerAccount.accountId))
      );
      if (editedFields.credentials) {
        const editedCredentialsSuccessModal = resolveEditedCredentialsSuccessModal({
          payloadData, telematicAccountFormConfig, title, content, goToDetailsPage,
        });
        if (editedCredentialsSuccessModal) {
          return editedCredentialsSuccessModal;
        }
      }
      if (editedFields.description) {
        return {
          title: title.concat('.ADDITIONAL_INFO_UPDATED'),
          content: t(content.concat('.ADDITIONAL_INFO_UPDATED')),
          constructor: Modal.success,
          onOk: goToDetailsPage,
          okText: 'TELEMATICS.ACCOUNT.BUTTON_GO_TO_DETAILS_PAGE',
        };
      }
    }

    const errorCode = getErrorCode(response);
    switch (errorCode) {
      case ERROR_CODE.SCANIA_VAT_NUMBER_NOT_FOUND:
      case ERROR_CODE.CONNECTIVITY_AGREEMENT_NOT_SIGNED:
        return {
          title: title.concat(`.${errorCode}`),
          content: t(content.concat(`.${errorCode}`)),
          constructor: Modal.error,
          width: 463,
          onOk: handleUserNeedsSupport,
          okText: 'TELEMATICS.ACCOUNT.BUTTON_NEED_SUPPORT',
        };
      default:
        return {
          title: title.concat('.DEFAULT'),
          content: t(content.concat('.DEFAULT')),
          constructor: Modal.error,
        };
    }
  };

  const dispatchTelematicAccountEdit = ({
    payload, editedFields, resetForm, handleUserNeedsSupport,
  }) => {
    if (submitClicked) {
      return;
    }
    setSubmitClicked(true);
    dispatch(updateTelematicAccount(accountId, payload, contextCompanyId))
      .then((response) => {
        const modalInfo = confirmationModalKeysAndConstructor(
          response,
          resetForm,
          editedFields,
          handleUserNeedsSupport,
        );
        setSubmitClicked(false);
        modalInfo.constructor({
          key: uuidv4(),
          title: t(modalInfo.title),
          content: modalInfo.content,
          onOk: modalInfo.onOk,
          okText: t(modalInfo.okText),
          centered: true,
          width: modalInfo.width,
          closable: true,
          maskClosable: true,
        });
      });
  };

  const loaderTextKey = (opType) => `TELEMATICS.ACCOUNT.${opType}.LOADER.${fetchStatus === GET_TELEMATIC_ACCOUNT.FAIL
    ? 'FAILED' : 'LOADING'}`;
  const renderLoader = (opType) => <Loader text={t(loaderTextKey(opType))} />;
  const renderErrorMessage = () => (
    <FullSpace height="500px">
      <span style={{ fontSize: '20px' }}>{t(loaderTextKey(operationType))}</span>
    </FullSpace>
  );

  const renderContent = () => {
    if (!initialValues) {
      setInitialValues(mapAccountToForm(account));
    }
    return (
      <>
        <BreadcrumbsItem to={RoutePath.telematicAccountsView}>{t('BREADCRUMB.TELEMATICS')}</BreadcrumbsItem>
        <BreadcrumbsItem to={RoutePath.telematicAccountDetails(accountId)}>
          {account.providerDisplayName}
        </BreadcrumbsItem>
        {
          isAddVehiclesOperation ? (
            <BreadcrumbsItem to={RoutePath.telematicAccountDetailsAddVehicles(accountId)}>
              {t('BREADCRUMB.TELEMATICS_ACCOUNT_ADD_NEW_VEHICLES')}
            </BreadcrumbsItem>
          ) : (
            <BreadcrumbsItem to={RoutePath.telematicAccountDetailsView(accountId)}>
              {t('BREADCRUMB.TELEMATICS_VIEW_ACCOUNT')}
            </BreadcrumbsItem>
          )
        }

        <TelematicAccountForm
          form={accountEditForm}
          submitDispatchFunction={dispatchTelematicAccountEdit}
          telematicAccountId={account.accountId}
          initialValues={initialValues}
          operationType={operationType}
          submitClicked={submitClicked}
          setSubmitClicked={setSubmitClicked}
        />
      </>
    );
  };

  const renderContentIfValidated = () => {
    if (accountValidated) {
      return renderContent();
    }
    if (companyNotMathed) {
      return <></>;
    }
    if (isWrongUpdateOperation) {
      return renderLoader(TelematicAccountOperationType.ADD_VEHICLES);
    }
    if (isWrongAddVehiclesOperation) {
      return renderLoader(TelematicAccountOperationType.UPDATE);
    }
    return renderLoader(operationType);
  };

  switch (fetchStatus) {
    case GET_TELEMATIC_ACCOUNT.START:
      return renderLoader(operationType);
    case GET_TELEMATIC_ACCOUNT.SUCCESS:
      return renderContentIfValidated();
    case GET_TELEMATIC_ACCOUNT.FAIL:
    default:
      return renderErrorMessage();
  }
}

TelematicAccountEditPage.propTypes = {};
