import React from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import { LanguageService as i18n } from '../../services';
import { FilterTag } from '../Styled';
import { DEFAULT_DATE } from '../../utils/constants/timeFormats';
import {
  connectionStatusOptions,
} from '../../modules/Telematics/TelematicAccountDetails/Components/DevicesTable.constants';
import { statusOptions } from '../../modules/Orders/Components/OrdersTable.constants';

const valuesWithLabel = [...connectionStatusOptions, ...statusOptions];

const toString = (value) => {
  if (value && moment.isMoment(value) && value.isValid()) {
    return value.format(DEFAULT_DATE);
  }
  const label = valuesWithLabel.find((op) => op.value === value)?.label;
  return label ? i18n.t(label) : value;
};

const parseValues = (values) => values.map(toString).join(', ');

const FilterTagComponent = ({ filterName, value, onRemove }) => (
  <FilterTag
    key={filterName}
    closable={!!onRemove}
    onClose={() => onRemove(filterName)}
    color="blue"
  >
    <b>
      {i18n.t(`PROPERTIES.${filterName}`)}
      :
      {' '}
    </b>
    {' '}
    {parseValues(value)}
  </FilterTag>
);

FilterTagComponent.propTypes = {
  filterName: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, MomentPropTypes.momentObj])).isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default FilterTagComponent;
