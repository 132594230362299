import axiosActionsFactory from '../../axiosHelpers';
import { UrlService } from '../../../../services';
import { getSelectedCompany } from '../../../selectors';

export const ACTIVATE_TELEMATIC_ACCOUNT_DEVICES = axiosActionsFactory('ACTIVATE_TELEMATIC_ACCOUNT_DEVICES');

const activateTelematicAccountDevices = (
  params, deviceIds,
) => (dispatch) => dispatch({
  type: ACTIVATE_TELEMATIC_ACCOUNT_DEVICES.START,
  payload: {
    request: {
      url: UrlService.activateDevices(),
      method: 'post',
      params,
      data: deviceIds,
    },
  },
});

export const activateDevices = (deviceIds) => (dispatch, getState) => {
  const state = getState();
  const contextCompanyId = getSelectedCompany(state);

  return dispatch(activateTelematicAccountDevices(
    { contextCompanyId }, deviceIds,
  ));
};
