import { DevicesColumnKey as ColumnKey } from './DevicesTable.constants';

export const vinField = (deviceId) => ['devices', deviceId, ColumnKey.vin];
export const licensePlateCountryCodeField = (deviceId) => ['devices', deviceId, ColumnKey.licensePlateCountryCode];
export const licensePlateNumberField = (deviceId) => ['devices', deviceId, ColumnKey.licensePlateNumber];
export const vehicleTypeField = (deviceId) => ['devices', deviceId, ColumnKey.vehicleType];

export const ERROR_CODE = {
  MISSING_LICENSE_PLATE_COUNTRY_CODE: 'MISSING_LICENSE_PLATE_COUNTRY_CODE',
  MISSING_VEHICLE_TYPE: 'MISSING_VEHICLE_TYPE',
  MISSING_LICENSE_PLATES: 'MISSING_LICENSE_PLATES',
  INVALID_LICENSE_PLATE: 'INVALID_LICENSE_PLATE',
  VEHICLE_LICENSE_PLATE_DUPLICATE: 'VEHICLE_LICENSE_PLATE_DUPLICATE',
  LICENSE_PLATE_ALREADY_EXISTS: 'LICENSE_PLATE_ALREADY_EXISTS',
  INVALID_VIN: 'INVALID_VIN',
  VIN_ALREADY_EXISTS: 'VIN_ALREADY_EXISTS',
  MULTIPLE_LICENSE_PLATE_NUMBER_CHANGES: 'MULTIPLE_LICENSE_PLATE_NUMBER_CHANGES',
  MULTIPLE_LICENSE_PLATE_COUNTRY_CODE_CHANGES: 'MULTIPLE_LICENSE_PLATE_COUNTRY_CODE_CHANGES',
  MULTIPLE_VIN_CHANGES: 'MULTIPLE_VIN_CHANGES',
  MULTIPLE_VEHICLE_TYPE_CHANGES: 'MULTIPLE_VEHICLE_TYPE_CHANGES',
};

export const LICENSE_PLATE_ERROR_CODES = [
  ERROR_CODE.MISSING_LICENSE_PLATES,
  ERROR_CODE.INVALID_LICENSE_PLATE,
  ERROR_CODE.VEHICLE_LICENSE_PLATE_DUPLICATE,
  ERROR_CODE.LICENSE_PLATE_ALREADY_EXISTS,
  ERROR_CODE.MULTIPLE_LICENSE_PLATE_NUMBER_CHANGES,
];

export const LICENSE_PLATE_COUNTRY_CODE_ERROR_CODES = [
  ERROR_CODE.MISSING_LICENSE_PLATE_COUNTRY_CODE,
  ERROR_CODE.MULTIPLE_LICENSE_PLATE_COUNTRY_CODE_CHANGES,
];

export const VIN_ERROR_CODES = [
  ERROR_CODE.INVALID_VIN,
  ERROR_CODE.VIN_ALREADY_EXISTS,
  ERROR_CODE.MULTIPLE_VIN_CHANGES,
];

export const VEHICLE_TYPE_ERROR_CODES = [
  ERROR_CODE.MISSING_VEHICLE_TYPE,
  ERROR_CODE.MULTIPLE_VEHICLE_TYPE_CHANGES,
];

export const POST_FORM_SUBMIT_ERROR_CODES = [
  ERROR_CODE.INVALID_LICENSE_PLATE,
  ERROR_CODE.VEHICLE_LICENSE_PLATE_DUPLICATE,
  ERROR_CODE.LICENSE_PLATE_ALREADY_EXISTS,
  ERROR_CODE.INVALID_VIN,
  ERROR_CODE.VIN_ALREADY_EXISTS,
  ERROR_CODE.MULTIPLE_LICENSE_PLATE_NUMBER_CHANGES,
  ERROR_CODE.MULTIPLE_LICENSE_PLATE_COUNTRY_CODE_CHANGES,
  ERROR_CODE.MULTIPLE_VIN_CHANGES,
  ERROR_CODE.MULTIPLE_VEHICLE_TYPE_CHANGES,
];
