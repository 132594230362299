import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RoutePath from '../../../RoutePath';
import { LanguageService as i18n } from '../../../services';
import { getSelectedCompany } from '../../../store/selectors';
import { submitTelematicAccountSupportRequired } from '../../../store/actions';
import { isSuccess, isError } from '../../../utils/responseUtils';
import { uuidv4 } from '../../../utils/uuid';

export function onClickSupportButton(telematicAccountId, form, store, history) {
  function preparePayloadForSupportNeeded({
    accountDisplayName,
    accountDescription,
    selectedProvider,
    notFromListProviderCheckbox,
    notFromListProviderName,
    notFromListProviderContactEmail,
    notFromListProviderLink,
    ...otherParams
  }) {
    return {
      providerId: selectedProvider,
      providerAccountId: telematicAccountId,
      credentials: otherParams,
    };
  }

  function supportNeededModalKeysAndConstructor(response) {
    const forSuccess = isSuccess(response);
    const forFailure = isError(response);
    const statusSuffix = (forSuccess && 'SUCCESS') || (forFailure && 'ERROR') || 'STARTED';
    const modalConstructor = (forSuccess && Modal.success) || (forFailure && Modal.error) || Modal.info;
    return {
      title: `TELEMATICS.ACCOUNT.CREATE.CONFIRMATION.TITLE.NEED_SUPPORT.${statusSuffix}`,
      content: `TELEMATICS.ACCOUNT.CREATE.CONFIRMATION.CONTENT.NEED_SUPPORT.${statusSuffix}`,
      constructor: modalConstructor,
    };
  }

  const payload = preparePayloadForSupportNeeded(form.getFieldsValue());
  const selectedCompany = getSelectedCompany(store.getState());
  store.dispatch(submitTelematicAccountSupportRequired(payload, selectedCompany))
    .then((response) => {
      const modalInfo = supportNeededModalKeysAndConstructor(response);
      modalInfo.constructor({
        key: uuidv4(),
        title: i18n.t(modalInfo.title),
        content: i18n.t(modalInfo.content),
        onOk: () => {
          history.push(RoutePath.telematicAccountsView);
        },
        centered: true,
        closable: true,
        maskClosable: true,
      });
    });
}

const SupportButton = ({ telematicAccountId, form, disabled }) => {
  const store = useStore();
  const history = useHistory();
  return (
    <Button
      icon={<MessageOutlined />}
      style={{ borderColor: '#1890FF', color: '#1890FF' }}
      onClick={() => onClickSupportButton(telematicAccountId, form, store, history)}
      disabled={disabled}
    >
      {i18n.t('TELEMATICS.ACCOUNT.BUTTON_NEED_SUPPORT')}
    </Button>
  );
};

SupportButton.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.any.isRequired,
  telematicAccountId: PropTypes.string,
  disabled: PropTypes.bool,
};

SupportButton.defaultProps = {
  telematicAccountId: undefined,
  disabled: false,
};

export default SupportButton;
