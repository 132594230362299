import {
  GET_TELEMATIC_ACCOUNT_DETAILS, GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY, REFRESH_TELEMATIC_ACCOUNT_DETAILS,
} from '../../../../actions';
import { normalizeTelematicAccountDetails } from '../../../../normalizers';

const initialState = {
  fetchStatus: GET_TELEMATIC_ACCOUNT_DETAILS.START,
  accountDetails: null,
};

function telematicAccountDetails(state = initialState, { type, payload }) {
  switch (type) {
    case GET_TELEMATIC_ACCOUNT_DETAILS.START:
      return { ...state, ...initialState };
    case GET_TELEMATIC_ACCOUNT_DETAILS.SUCCESS:
    case REFRESH_TELEMATIC_ACCOUNT_DETAILS.SUCCESS:
      return {
        ...state,
        fetchStatus: GET_TELEMATIC_ACCOUNT_DETAILS.SUCCESS,
        accountDetails: normalizeTelematicAccountDetails(state.accountDetails, payload.data),
      };
    case GET_TELEMATIC_ACCOUNT_DETAILS.FAIL:
    case REFRESH_TELEMATIC_ACCOUNT_DETAILS.FAIL:
      return {
        ...state,
        fetchStatus: GET_TELEMATIC_ACCOUNT_DETAILS.FAIL,
        accountDetails: null,
      };
    case GET_TELEMATIC_ACCOUNT_LIST_FOR_COMPANY.SUCCESS:
      return initialState;
    default:
      return state;
  }
}

export default telematicAccountDetails;
