import { combineReducers } from 'redux';
import { DeviceContext } from '../../../../../types';
import devices from './devices.reducer';
import pagination from './pagination.reducer';
import sorting from './sorting.reducer';
import editMode from './editMode.reducer';
import prevAction from './prevAction.reducer';

const createDevicesReducer = (deviceContext) => combineReducers({
  data: devices(deviceContext),
  pagination: pagination(deviceContext),
  sorting: sorting(deviceContext),
  editMode: editMode(deviceContext),
  prevAction: prevAction(deviceContext),
});

export default combineReducers({
  [DeviceContext.NEW]: createDevicesReducer(DeviceContext.NEW),
  [DeviceContext.CONNECTED]: createDevicesReducer(DeviceContext.CONNECTED),
  [DeviceContext.DEACTIVATED]: createDevicesReducer(DeviceContext.DEACTIVATED),
});
