import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import RoutePaths from '../../RoutePath';
import { getSelectedCompany } from '../../store/selectors';
import SelectCompanyFirstComponent from '../../components/Vehicle/SelectCompanyFirstComponent';
import TelematicAccountCreationPage from './TelematicAccountCreation/TelematicAccountCreationPage';
import TelematicAccountListPage from './TelematicAccountList/TelematicAccountListPage';
import TelematicAccountDetailsPage from './TelematicAccountDetails/TelematicAccountDetailsPage';
import TelematicAccountEditPage from './TelematicAccountEdit/TelematicAccountEditPage';
import { TelematicAccountOperationType } from '../../types/enums/telematicAccountFormViewType.enum';

export default () => {
  const selectedCompany = useSelector(getSelectedCompany);
  return (
    <>
      {
        !selectedCompany ? <SelectCompanyFirstComponent /> : (
          <>
            <Route
              exact
              path={RoutePaths.telematicAccountsView}
              component={TelematicAccountListPage}
            />
            <Route
              exact
              path={RoutePaths.telematicAccountsCreate}
              component={TelematicAccountCreationPage}
            />
            <Route
              exact
              path={RoutePaths.telematicAccountDetails()}
              component={TelematicAccountDetailsPage}
            />
            <Route
              path={RoutePaths.telematicAccountDetailsView()}
              component={() => <TelematicAccountEditPage operationType={TelematicAccountOperationType.UPDATE} />}
            />
            <Route
              path={RoutePaths.telematicAccountDetailsAddVehicles()}
              component={() => <TelematicAccountEditPage operationType={TelematicAccountOperationType.ADD_VEHICLES} />}
            />
          </>
        )
      }
    </>
  );
};
