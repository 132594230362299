import React from 'react';
import { Button } from 'antd';
import { SearchDropdownContent, SearchInput } from './FilterDropdown.styled';

const InputFilterDropdown = (filter, setFilters, t, highlight = false) => ({
  // eslint-disable-next-line react/prop-types
  setSelectedKeys, selectedKeys, confirm, clearFilters,
}) => {
  const setFilterValue = (value) => {
    setFilters({ [filter.name]: value });
  };
  const handleSearch = () => {
    setFilterValue(selectedKeys);
    if (!highlight) {
      confirm();
    }
  };

  const handleReset = () => {
    setFilterValue(null);
    if (!highlight) {
      clearFilters({ confirm: true });
    }
  };
  return (
    <SearchDropdownContent>
      <SearchInput
        placeholder={
          `${t(highlight
            ? 'COMMON.HIGHLIGHT'
            : 'COMMON.SEARCH_BY')} ${t(`PROPERTIES.${filter.properties ? filter.properties[0].name : filter.name}`)}`
        }
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch()}
      />
      <div className="ant-table-filter-dropdown-btns">
        <Button
          onClick={() => handleReset()}
          size="small"
          type="link"
          disabled={!selectedKeys[0]}
        >
          {t('COMMON.RESET')}
        </Button>
        <Button
          type="primary"
          onClick={() => handleSearch()}
          size="small"
        >
          {t('COMMON.OK')}
        </Button>
      </div>
    </SearchDropdownContent>
  );
};

export default InputFilterDropdown;
