import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import { FormInput, FormItem } from '../../../components';
import TelematicProviderSelect from './TelematicProviderSelect';
import { Styles, Logo, Wrapper } from './TelematicAccount.styled';
import { telematicProviderPropTypes } from '../../../types';

export default function TelematicProviderChooserSection({
  providers,
  initialProviderId,
  selectedProvider,
  setSelectedProvider,
  setDetailsDisabled,
  creationView,
  resetAccountDetailsFields,
}) {
  const { t } = useTranslation();
  const notFromListCheckboxName = 'notFromListProviderCheckbox';
  const [isProviderNotFromListChecked, setProviderNotFromListChecked] = useState(false);
  const selectedProviderHasVins = selectedProvider?.fields.some(({ fieldName }) => fieldName === 'vins');
  const shouldShowAccountDisplayName = !creationView && !selectedProviderHasVins;
  const shouldHideAccountDescription = isProviderNotFromListChecked || selectedProviderHasVins;

  useEffect(() => {
    const initialProvider = providers.find(({ providerId }) => providerId === initialProviderId);
    setSelectedProvider(initialProvider);
  }, [initialProviderId, providers, setSelectedProvider]);
  const handleProviderChange = (value) => {
    const newlySelectedProvider = providers.find(({ providerId }) => providerId === value);
    setSelectedProvider(newlySelectedProvider);
    resetAccountDetailsFields(newlySelectedProvider);
  };

  const handleNotFromListCheckboxChange = (value) => {
    setProviderNotFromListChecked(value.target.checked);
    setDetailsDisabled(value.target.checked);
  };

  const shouldShowProvider = (forUpdateOnly) => (!creationView || !forUpdateOnly);
  const providersToShow = providers.filter(({ forUpdateOnly }) => shouldShowProvider(forUpdateOnly));
  const providerGroups = [
    {
      label: t('TELEMATICS.ACCOUNT.CREATE.LABELS.TELEMATIC_PROVIDER_OEM'),
      providers: providersToShow.filter(({ typeOem }) => typeOem === true),
    },
    {
      label: t('TELEMATICS.ACCOUNT.CREATE.LABELS.TELEMATIC_PROVIDER_GPS'),
      providers: providersToShow.filter(({ typeOem }) => typeOem !== true),
    },
  ];

  return (
    <>
      <Col span={11}>
        <Row>
          <Col>
            {shouldShowAccountDisplayName && (
              <FormItem
                style={Styles.wide}
                label={t('TELEMATICS.ACCOUNT.CREATE.LABELS.ACCOUNT_DISPLAY_NAME')}
                name="accountDisplayName"
              >
                <FormInput
                  style={Styles.wide}
                  placeholder={t('TELEMATICS.ACCOUNT.CREATE.PLACEHOLDERS.ACCOUNT_DISPLAY_NAME')}
                  disabled
                />
              </FormItem>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <FormItem
              label={t('TELEMATICS.ACCOUNT.CREATE.LABELS.TELEMATIC_PROVIDER')}
              name="selectedProvider"
              disabled={isProviderNotFromListChecked}
              rules={!isProviderNotFromListChecked ? [
                { required: creationView, message: t('TELEMATICS.ACCOUNT.CREATE.ERRORS.REQUIRED') },
              ] : []}
            >
              <TelematicProviderSelect
                defaultValue={initialProviderId}
                providerGroups={providerGroups}
                disabled={isProviderNotFromListChecked || !creationView}
                onChange={handleProviderChange}
              />
            </FormItem>
          </Col>
        </Row>
        {creationView ? (
          <>
            <Row>
              <Col>
                <FormItem
                  name={notFromListCheckboxName}
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={handleNotFromListCheckboxChange}
                  >
                    {t('TELEMATICS.ACCOUNT.CREATE.LABELS.TELEMATIC_PROVIDER_OTHER')}
                  </Checkbox>
                </FormItem>
                <Wrapper gutter={100} hidden={!isProviderNotFromListChecked}>
                  <Col span={10}>
                    <FormItem
                      style={Styles.narrow}
                      label={t('TELEMATICS.ACCOUNT.CREATE.LABELS.TELEMATIC_PROVIDER_OTHER_NAME')}
                      name="notFromListProviderName"
                      rules={isProviderNotFromListChecked ? [
                        { required: true, message: t('TELEMATICS.ACCOUNT.CREATE.ERRORS.REQUIRED') },
                        { type: 'string', whitespace: true, message: t('TELEMATICS.ACCOUNT.CREATE.ERRORS.BLANK') },
                        { type: 'string', max: 50, message: t('TELEMATICS.ACCOUNT.CREATE.ERRORS.TOO_LONG_50') },
                      ] : []}
                    >
                      <FormInput
                        style={Styles.narrow}
                        placeholder={t('TELEMATICS.ACCOUNT.CREATE.PLACEHOLDERS.TELEMATIC_PROVIDER_OTHER_NAME')}
                        disabled={!isProviderNotFromListChecked}
                      />
                    </FormItem>
                  </Col>

                  <Col span={10}>
                    <FormItem
                      style={Styles.narrow}
                      label={t('TELEMATICS.ACCOUNT.CREATE.LABELS.TELEMATIC_PROVIDER_OTHER_EMAIL')}
                      name="notFromListProviderContactEmail"
                      rules={isProviderNotFromListChecked ? [
                        { required: true, message: t('TELEMATICS.ACCOUNT.CREATE.ERRORS.REQUIRED') },
                        { type: 'string', max: 50, message: t('TELEMATICS.ACCOUNT.CREATE.ERRORS.TOO_LONG_50') },
                        {
                          required: false,
                          type: 'email',
                          message: t('TELEMATICS.ACCOUNT.CREATE.ERRORS.INVALID_PATTERN_EMAIL'),
                        },
                      ] : []}
                    >
                      <FormInput
                        style={Styles.narrow}
                        placeholder={t('TELEMATICS.ACCOUNT.CREATE.PLACEHOLDERS.TELEMATIC_PROVIDER_OTHER_EMAIL')}
                        disabled={!isProviderNotFromListChecked}
                      />
                    </FormItem>
                  </Col>
                </Wrapper>
              </Col>
            </Row>

            <Wrapper hidden={!isProviderNotFromListChecked}>
              <Col>
                <FormItem
                  style={Styles.wide}
                  label={t('TELEMATICS.ACCOUNT.CREATE.LABELS.TELEMATIC_PROVIDER_OTHER_LINK')}
                  name="notFromListProviderLink"
                  rules={isProviderNotFromListChecked ? [
                    { required: true, message: t('TELEMATICS.ACCOUNT.CREATE.ERRORS.REQUIRED') },
                    { type: 'string', max: 50, message: t('TELEMATICS.ACCOUNT.CREATE.ERRORS.TOO_LONG_50') },
                    {
                      required: false,
                      type: 'url',
                      message: t('TELEMATICS.ACCOUNT.CREATE.ERRORS.INVALID_PATTERN_URL'),
                    },
                  ] : []}
                  normalize={(value) => value.toLowerCase()}
                >
                  <FormInput
                    style={Styles.wide}
                    placeholder={t('TELEMATICS.ACCOUNT.CREATE.PLACEHOLDERS.TELEMATIC_PROVIDER_OTHER_LINK')}
                    disabled={!isProviderNotFromListChecked}
                  />
                </FormItem>
              </Col>
            </Wrapper>
          </>
        ) : <></>}

        <Wrapper hidden={shouldHideAccountDescription}>
          <Col>
            <FormItem
              label={t('TELEMATICS.ACCOUNT.CREATE.LABELS.ACCOUNT_DESCRIPTION')}
              name="accountDescription"
              style={Styles.narrow}
            >
              <TextArea
                disabled={isProviderNotFromListChecked}
                style={Styles.wide}
              />
            </FormItem>
          </Col>
        </Wrapper>

      </Col>
      <Col span={13}>
        {!isProviderNotFromListChecked && selectedProvider?.providerLogoUrl ? (
          <Row justify="end" align="top">
            <Col span={19}>
              <Row>
                <Logo
                  alt="Telematic provider logo."
                  src={selectedProvider.providerLogoUrl}
                />
              </Row>
            </Col>
          </Row>
        ) : <></>}
      </Col>
    </>
  );
}

TelematicProviderChooserSection.propTypes = {
  providers: PropTypes.arrayOf(telematicProviderPropTypes),
  initialProviderId: PropTypes.string,
  selectedProvider: telematicProviderPropTypes,
  setSelectedProvider: PropTypes.func.isRequired,
  setDetailsDisabled: PropTypes.func.isRequired,
  creationView: PropTypes.bool,
  resetAccountDetailsFields: PropTypes.func.isRequired,
};

TelematicProviderChooserSection.defaultProps = {
  providers: [],
  initialProviderId: undefined,
  creationView: true,
  selectedProvider: null,
};
