import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XhrBackend from 'i18next-xhr-backend';
import env from '../config';

const init = () => i18n
  .use(LanguageDetector)
  .use(XhrBackend)
  .use(initReactI18next)
  .init({
    debug: env.type !== 'production',
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ['en'],
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'language',
    },
    backend: {
      loadPath: `/locales/{{lng}}/translation.json?v=${env.version}`,
    },
    fallbackLng: 'en',
  });

export default {
  init,
  t: (i18nKey, params) => i18n.t(i18nKey, params),
};
