import { DeviceContext } from '../../../../types';

const SET_NEW_DEVICES_SORTING = 'SET_NEW_DEVICES_SORTING';
const SET_CONNECTED_DEVICES_SORTING = 'SET_CONNECTED_DEVICES_SORTING';
const SET_DEACTIVATED_DEVICES_SORTING = 'SET_DEACTIVATED_DEVICES_SORTING';

export const SET_DEVICES_SORTING = {
  [DeviceContext.NEW]: SET_NEW_DEVICES_SORTING,
  [DeviceContext.CONNECTED]: SET_CONNECTED_DEVICES_SORTING,
  [DeviceContext.DEACTIVATED]: SET_DEACTIVATED_DEVICES_SORTING,
};

export const setDevicesSorting = (newSorting, deviceContext = DeviceContext.CONNECTED) => ({
  type: SET_DEVICES_SORTING[deviceContext],
  payload: newSorting,
});
